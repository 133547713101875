angular.module('hcap-analytics')
  .factory('Util', [
    '$state',
    'AssessmentService',
    'UserService',
  function(
    $state,
    AssessmentService,
    UserService,
  ) {
    const ignorable = [
      'page_break',
      'caption',
      'caption_minor',
      'team_effective_header',
      'team_table_contrib',
      'team_table_interact',
      'team_table_track',
      'team_table_quality',
      'team_table_knowledge',
    ];

    const noPrompt = [
      'page_break',
      'team_effective_header',
      'team_table_contrib',
      'team_table_interact',
      'team_table_track',
      'team_table_quality',
      'team_table_knowledge',
    ];

    return {
      phaseDisplay(index, phases, short) {
        const type = phases[index].type;

        const text = short ? {
          setup: 'Charter & Skills',
          prep: 'Teamwork Prep',
          prog: 'Health Report',
          eval: 'Evaluations',
        } : {
          setup: 'Charter and Skills Inventory',
          prep: 'Preparing for Teamwork',
          prog: 'Team Health Report',
          eval: 'Project Milestone Evaluation',
        };

        if (!['prog', 'eval'].includes(type)) {
          return text[type];
        } else {
          let count = 0;

          for (let i = 0; i < phases.length; i++) {
            if (phases[i].type === type) {
              ++count;

              if (i === index) {
                return text[type] + ' ' + count;
              }
            }
          }
        }
      },
      canIgnore(q) {
        return ignorable.includes(q.type);
      },
      hideKeyBox(q) {
        return ignorable.includes(q.type);
      },
      hidePromptBox(q) {
        return noPrompt.includes(q.type);
      },
      async viewAssessmentState(assessmentGuid, templateId, participant) {
        if (participant?.completed) {
          return {
            state: 'participate.result',
            params: {
              templateId,
              participantId: participant.guid,
            },
            options: { inherit: false },
          };
        } else {
          if (!participant) {
            const user = UserService.getUser();
            return new Promise((res, reject) => {
              AssessmentService.addParticipant(assessmentGuid, {
                email: user.email,
              }, false, resData => {
                res({
                  state: 'participate.questions',
                  params: {
                    templateId,
                    participantId: resData.participant.guid,
                  },
                  options: { inherit: false, }
                });
              });
            });
          } else {
            return {
              state: 'participate.questions',
              params: {
                templateId,
                participantId: participant.guid,
              },
              options: { inherit: false, }
            };
          }
        }
      },
      async viewAssessment(assessmentGuid, templateId, participant) {
        const state = await this.viewAssessmentState(assessmentGuid, templateId, participant);
        $state.go(state.state, state.params, state.options);
      },
      parseSheet(workbook) {
        const result = {};
        workbook.SheetNames.forEach(function(sheetName) {
          const roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);

          if (roa.length > 0) {
            result[sheetName] = roa;
          }
        });

        return result;
      },
    };
  },
]);
