angular.module('hcap-analytics')
  .controller('ResetCtrl', ['$scope', 'UserService', 'StateService', 'AlertService', '$http', '$state', function($scope, UserService, StateService, AlertService, $http, $state) {

    $scope.errorMessage = null;
    $scope.isResetDisabled = false;

    $scope.user = {
      email: null,
    };

    $scope.reset = function(valid) {
      if (!valid) {
        console.log('invalid');
        return;
      }
      $scope.isResetDisabled = true;
      $scope.errorMessage = null;
      $http.post('/api/user/' + $scope.user.email.toLowerCase() + '/reset').then(function(response) {
        $scope.isResetDisabled = false;
        if (!response.data.success) {
          $scope.errorMessage = response.data.message;
        } else {
          AlertService.alert('An email notification containing a Password Reset Link has been sent to ' + $scope.user.email.toLowerCase() + '.', 'Password Reset', function() {
            $state.transitionTo('signin');
          });
        }
      }, function(error) {
        console.log(error);
      });
    };
  }]);
