angular.module('hcap-analytics').controller('ActivateCtrl', [
  '$scope',
  '$http',
  '$timeout',
  '$location',
  'UserService',
  'StateService',
function(
  $scope,
  $http,
  $timeout,
  $location,
  UserService,
  StateService
) {
  $scope.data = {
    errorMessage: null,
    user: null,
    disableAction: false,
    loading: true,
  };

  let code = $location.search().code;
  if (!code || code.length == 0) {
    code = 'empty';
  }

  $http.get('/api/activate/' + code).then(function(response) {
    const password = document.getElementById('password');
    const password2 = document.getElementById('password2');

    const validatePassword = () => {
      if (password.value != password2.value) {
        password2.setCustomValidity('Passwords Don\'t Match');
      } else {
        password2.setCustomValidity('');
      }
    };

    password.onchange = validatePassword;
    password2.onkeyup = validatePassword;

    if (response.data.success) {
      $scope.data.errorMessage = null;
      $scope.data.user = response.data.user;

      $scope.data.loading = false;
    } else {
      window.location.href = '/';
    }

  }, function(err) {
    console.log(err);
    $scope.data.errorMessage = 'Service Unavailable - Please try again at a later time.';
  });

  $scope.activate = function(valid) {
    if (!valid) {
      return;
    }
    if ($scope.data.user.password != $scope.data.user.password_confirm) {
      $scope.data.errorMessage = 'Passwords do not match.';
      return;
    }
    const body = {
      password: $scope.data.user.password,
    };

    $scope.data.disableAction = true;

    $http.post('/api/activate/' + code, body).then(function(response) {
      $scope.data.disableAction = false;

      if (response.data.success) {
        $scope.data.errorMessage = null;

        UserService.signIn($scope.data.user, function(user, errorMessage) {
          if (!user) {
            $scope.data.errorMessage = errorMessage;
          } else {
            StateService.land();
          }
        });
      } else {
        $scope.data.errorMessage = 'Service Unavailable - Please try again at a later time.';
      }
    }, function(err) {
      $scope.data.errorMessage = 'Service Unavailable - Please try again at a later time.';
    });
  };
}]);
