angular.module('hcap-analytics')
  .service('UserService', ['$location', '$http', '$state', 'CohortsService', '$q', function($location, $http, $state, CohortsService, $q) {

    this.pose = function(guid) {
      $http.post('./api/user/pose/' + guid).then(function(response) {
        if (response.data.success) {
          window.location.pathname = '/v2/dashboard';

	  const user = response.data.user;
	  localStorage.setItem('user', JSON.stringify(user));
        }
      }, function(error) {

      });
    };

    this.getUserDetails = function(guid, callback) {
      $http.get('./api/user/details/' + guid).then(function(response) {
        callback(response.data);
      }, function(error) {
        callback(null);
      });
    };

    this.saveProfile = function(profile, callback) {
      $http.post('./api/user/profile', profile).then(function(response) {
        const user = response.data.user;

        if (response.data.success) {
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          localStorage.removeItem('user');
        }

        callback(user, response.data.message);
      }, function(error) {
        localStorage.removeItem('user');
        callback(null);
      });
    };

    this.getProfile = function() {
      return $http.get('./api/user/profile').then(response => {
        return response.data.user;
      });
    };

    this.getConsentStatus = function(emails, callback) {
      $http.post('./api/user/consentStatus', { emails: emails }).then(function(response) {
        callback(response.data);
      }, function(err) {
        console.log(err);
      });
    };

    this.getConsentText = function(callback) {
      $http.get('./api/user/consent').then(function(response) {
        callback(response.data.consent);
      }, function(err) {
        console.log(err);
      });
    };

    this.isSignedIn = function() {
      return !!this.getUser();
    };

    this.validateEmail = function(email) {
      email = email || '';
      email = email.toLowerCase();
      return $http.get('./api/user/validate/' + email)
        .then(function(response) {
          return response.data;
        })
        .catch(function(err) {
          return false;
        });
    };

    this.getUser = function() {
      const user = JSON.parse(localStorage.getItem('user'));

      if (user && user.email) {
        return user;
      }

      return null;
    };

    this.updateUser = user => {
      localStorage.setItem('user', JSON.stringify(user));
    };

    this.submitCode = code => {
      return $http.post('./api/user/activationCode', { code }).then(res => {
        const user = this.getUser();
        user.perms = res.data;
        this.updateUser(user);
      });
    };

    this.hasPerm = perm => {
      if (!Array.isArray(this.getUser().perms)) {
        // legacy
        return false;
      } else {
        return this.getUser().perms.indexOf(perm) !== -1;
      }
    };

    this.isOrganizer = () => {
      const user = this.getUser();
      return user.role === 'Organizer';
    };

    this.hasTeams = async () => {
      const summary = await CohortsService.summary(true);
      return summary.length > 0;
    };

    this.getAll = function(callback) {
      $http.get('./api/user/all').then(function(response) {
        callback(response.data);
      }, function(err) {
        console.log(err);
      });
    };

    this.search = function(query, callback) {
      $http.get('./api/user/search/' + encodeURIComponent(query)).then(function(response) {
        callback(response.data);
      }, function(err) {
        console.log(err);
      });
    };

    this.signIn = function(user, callback) {
      $http.post('./api/user/signIn', user).then(function(response) {
        const user = response.data.user;

        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          localStorage.removeItem('user');
        }

        callback(user, response.data.message);
      }, function(error) {
        localStorage.removeItem('user');
        callback(null);
      });
    };

    this.signUp = function(user, callback, express = false) {
      const endpoint = express ? './api/user/signUpExpress' : './api/user/signUp';
      $http.post(endpoint, user).then(function(response) {
        const user = response.data.user;

        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          localStorage.removeItem('user');
        }
        callback(user, response.data.message);
      }, function(error) {
        localStorage.removeItem('user');
        callback(user);
      });
    };

    this.signOut = function() {
      return $http.post('./api/user/signOut').finally(function(response) {
        localStorage.removeItem('user');
        window.location.pathname = '/';
      });
    };

    this.hasSetConsentStatus = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        return $q.resolve(![undefined, null].includes(user.consent));
      } else {
        return this.getProfile().then(profile => {
          return ![undefined, null].includes(profile.consent);
        });
      }
    };

    this.setRole = function(guid, role) {
      return $http.post(`./api/user/${guid}/role`, {
        role,
      });
    };

    this.updatePerms = async (users) => {
      return await $http.post(`./api/user/permissions`, users);
    };
  }]);
