angular.module('hcap-analytics')
.run([
  '$rootScope',
  'StateService',
  '$anchorScroll',
  '$location',
  'UserService',
  '$http',
  '$state',
  'InvitesService',
function (
  $rootScope,
  StateService,
  $anchorScroll,
  $location,
  UserService,
  $http,
  $state,
  InvitesService,
) {

	// $rootScope.templateCategories = [
	// 	{ value: "personality", caption: "Personality" },
	// 	{ value: "values", caption: "Values" },
	// 	{ value: "mental_capacity", caption: "Mental Capacity" },
	// 	{ value: "interpersonal_skills", caption: "Interpersonal Skills" },
	// 	{ value: "leadership_servant", caption: "Leadership - Servant" },
	// 	{ value: "leadership_transformational", caption: "Leadership - Transformational" }
	// ]
	//
	// $rootScope.templateCategoryCaptions = {}
	// $rootScope.templateCategories.forEach(function(category) {
	// 	$rootScope.templateCategoryCaptions[category.value] = category.caption
	// })

	$rootScope.templateStatus = [
		{ value: "archived", caption: "Archived" },
		{ value: "published", caption: "Published" }
	]
	$rootScope.templateStatusCaptions = {}
	$rootScope.templateStatus.forEach(function(status) {
		$rootScope.templateStatusCaptions[status.value] = status.caption
	})

	// $rootScope.templateVisibility = [
	// 	{ value: "private", caption: "Private" },
	// 	{ value: "public", caption: "Public" }
	// ]
	// $rootScope.templateVisibilityCaptions = {}
	// $rootScope.templateVisibility.forEach(function(visibility) {
	// 	$rootScope.templateVisibilityCaptions[visibility.value] = visibility.caption
	// })

    //
	// $rootScope.$on('$routeChangeSuccess', function(newRoute, oldRoute) {
	// 	if($location.hash()) {
	// 		$anchorScroll()
	// 	}
	// })
    //
	// $rootScope.$on('$locationChangeSuccess', function() {
	// 	if($rootScope.previousLocation == $location.path()) {
	// 		console.log("Back Button Pressed")
	// 	}
	// })

  if (UserService.isSignedIn()) {
    InvitesService.update();
  }

	$rootScope.currentYear=(new Date()).getFullYear()

	monitorSignInStatus();

	function monitorSignInStatus() {
    if (UserService.isSignedIn()) {
      $http.get('./api/user').then(function(response) {
        const user = UserService.getUser();

        if (user && !response.data.user) {
          UserService.signOut();
        }
      });
    }
		setTimeout(monitorSignInStatus, 5000);
	}

}])
