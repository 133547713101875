angular.module('hcap-analytics')
.controller('AssessmentSetupCtrl', ['$scope','$http','$state','$stateParams','AssessmentService','TemplateService','AlertService', function($scope,$http,$state,$stateParams,AssessmentService,TemplateService,AlertService) {

	$scope.templates = null;
	$scope.guid=$stateParams.guid
	$scope.assessment=null
	$scope.newParticipant={}
	$scope.reports={}

  TemplateService.getAll().then(templates => {
		$scope.templates = templates.filter(function(template) {
			return template.status == 'published'
		});
	});

	AssessmentService.get($scope.guid,function(response){
		$scope.assessment=response.assessment
	})

	$scope.getAssessmentFileName=function(){
		return $scope.assessment.name.replace(new RegExp(' ','g'),'_').replace(new RegExp('#','g'),'')+'.csv';
	}

	$scope.getParticipantList=function(){
		var list=[];
		$scope.assessment.participants.forEach(function(participant){
			participant.email = participant.email.toLowerCase();
			list.push({
				Name:participant.name,
				Email:participant.email,
				Group:participant.group
			});
		})
		console.log(list)
		return list;
	}

	$scope.startAssessment=function(){
		if($scope.assessment.status=='started') {
			return
		}
		if($scope.assessment.participants.length==0) {
			AlertService.warning("There are no participants in this assessment.","Unable to start assessment")
			return
		}
		AlertService.confirm("Are you sure you want to start this assessment?",null,function(choice){
			if(choice) {
				AssessmentService.start($scope.guid,function(ret){
					if(ret.success) {
						$scope.assessment=ret.assessment
					} else {
						AlertService.alert(ret.message,"Error")
					}
				})
			}
		})
	}
	$scope.stopAssessment=function(){
		if($scope.assessment.status=='stopped') {
			return
		}
		AlertService.confirm("Are you sure you want to stop this assessment?",null,function(choice){
			if(choice) {
				AssessmentService.stop($scope.guid,function(ret){
					if(ret.success) {
						$scope.assessment=ret.assessment
					} else {
						AlertService.alert(ret.message,"Error")
					}
				})
			}
		})
	}

	$scope.addParticipant=function(){
		var valid=true
		var props=["name","email"/*,"group"*/]
		props.forEach(function(prop){
			if(!$scope.newParticipant[prop] || $scope.newParticipant[prop]=="") {
				valid=false
				return false
			}
		})
		if(!valid) {
			return
		}
		AssessmentService.addParticipant($scope.guid, $scope.newParticipant, true, function(ret) {
			if(ret.success) {
				$scope.assessment.participants.push(ret.participant);
				$scope.newParticipant={}
			} else {
				AlertService.warning("Unable to add participant",ret.message)
			}
		})
	}

	$scope.removeParticipant=function(participant) {
		AlertService.confirm("Are you sure you want to remove this participant?","Participant Removal",function(choice){
			if(choice) {
				AssessmentService.removeParticipant($scope.guid,participant.guid,function(ret){
					if(ret.success) {
						$scope.assessment=ret.assessment
					} else {
						AlertService.warning("Unable to remove participant",ret.message)
					}
				})
			}
		})

	}

	$scope.reopenParticipant=function(participant) {
		AlertService.confirm("Are you sure you want to reopen the assessment this participant?","Reopen Assessment",function(choice){
			if(choice) {
				AssessmentService.reopenParticipant($scope.guid,participant.guid,function(ret){
					if(ret.success) {
						$scope.assessment=ret.assessment
					} else {
						AlertService.warning("Unable to reopen assessment for participant",ret.message)
					}
				})
			}
		})
	}


	$scope.onPropChanged=function(prop) {
		console.log(prop,$scope.assessment[prop])
		AssessmentService.modifyProperty($scope.guid,prop,$scope.assessment[prop],function(ret){
			console.log('updated',prop,$scope.assessent[prop])
		})
	}

	$scope.read = function (workbook) {
		var contents=to_json(workbook)
		var participants=[]
		for(var sheetName in contents) {
			var sheetData=contents[sheetName]
			sheetData.forEach(function(row){
				var entry={
					name: row.Name,
					email: row.Email.toLowerCase(),
				}
				if(!entry.name && row['First Name'] && row['Last Name']) {
					entry.name=row['First Name']+' '+row['Last Name']
				}
				// if(row.Group) {
				// 	entry.group=row.Group
				// }
				participants.push(entry)
			})
		}
		if(participants.length==0) {
			AlertService.warning("No participants found in the file.","Error")
			return
		}
		// participants=_.shuffle(participants)
		// participants=_.shuffle(participants)
		// participants.forEach(function(participant,index){
		// 	if(!participant.group) {
		// 		participant.group=String((index%6)+1)
		// 	}
		// })
		AssessmentService.addParticipants($scope.guid, participants, true, function(ret) {
			if(ret.success) {
				$scope.assessment.participants = $scope.assessment.participants.concat(ret.participants);
				$scope.uploadFileContents={}
			} else {
				AlertService.warning("Unable to Add Participants",ret.message)
			}
		})
	}

	$scope.error = function (e) {
		console.log(e)
		AlertService.warning("Unable read file","Error")
	}

	function to_json(workbook) {
		var result = {}
		workbook.SheetNames.forEach(function(sheetName) {
			var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
			if(roa.length > 0){
				result[sheetName] = roa
			}
		})
		return result
	}
}])
