angular.module('hcap-analytics')
.controller('RaterFinishCtrl', [
  '$scope',
  'UserService',
function(
  $scope,
  UserService,
) {
  $scope.data = {
    loaded: false,
    consentSet: null,
  };

  UserService.hasSetConsentStatus().then(consentSet => {
    $scope.data.loaded = true;
    $scope.data.consentSet = consentSet;
  });
}]);
