angular.module('hcap-analytics')
.controller('SectionCtrl', [
  '$location',
  '$scope',
  '$stateParams',
  '$state',
  '$uibModal',
  '$q',
  'AssessmentService',
  'TemplateService',
  'sectionsConfig',
  'Util',
  'UserService',
function(
  $location,
  $scope,
  $stateParams,
  $state,
  $uibModal,
  $q,
  AssessmentService,
  TemplateService,
  sectionsConfig,
  Util,
  UserService,
) {

  $scope.data = {
    section: sectionsConfig.find(section => section.id === $stateParams.id),
    going: false,
  };

  if (!!$location.search().civility) {
    $scope.data.section.assessments.push(
      {
        name: 'Civility',
        id: 'VgTUjfZOHs9LqSkhPLDhPF',
        desc: '<ul><li>Gives a picture of areas for development (i.e., incivility behaviours) and areas of strength (i.e., positive leadership behaviours and inclusion)</li><li>Provides the opportunity for elaboration on the event which may help participants make sense of the event and cope with their emotions as well as provide a greater understanding of the context incivility is occurring in</li></ul>',
      },
    );
  }

  $scope.assessments = [];
  try {
    localStorage.setItem('sectionSelected', $scope.data.section.id);
  } catch (e) {}
  $scope.sectionSelected = $scope.data.section.id;

  const viewAssessment = assessment => {
    if (!$scope.data.going) {
      $scope.data.going = true;
      Util.viewAssessment(assessment.id, assessment.templateGuid || assessment.template_guid, assessment.participant);
    }
  }

  $scope.assessmentAction = assessment => {
    if (!$scope.isAuthorized(assessment)) {
      const pageScope = $scope;

      $uibModal.open({
        templateUrl: 'templates/partials/_activationCode.html',
        controller: ['$uibModalInstance', '$scope', ($uibModalInstance, $scope) => {
          $scope.data = {
            code: null,
            error: false,
          };

          $scope.submit = () => {
            if ($scope.data.code) {
              UserService.submitCode($scope.data.code).then(() => {
                if (pageScope.isAuthorized(assessment)) {
                  $uibModalInstance.dismiss('cancel');
                  Util.viewAssessment(assessment.id, assessment.templateGuid || assessment.template_guid, assessment.participant);
                } else {
                  $scope.data.error = true;
                }
              }).catch(e => {
                $scope.data.error = true;
              });
            }
          };

          $scope.close = () => {
            $uibModalInstance.dismiss('cancel');
          };
        }],
      });
    } else {
      Util.viewAssessment(assessment.id, assessment.templateGuid || assessment.template_guid, assessment.participant);
    }
  };

  $scope.isAuthorized = assessment => !assessment.permissions?.length || assessment.permissions.some(p => UserService.hasPerm(p));

  $scope.assessmentActionText = assessment => {
    if (!$scope.isAuthorized(assessment)) {
      return 'Enter Activation Code';
    } else if ($scope.assessmentCompleted(assessment)) {
      return 'View Results of Assessment';
    } else {
      return 'Start Assessment';
    }
  };

  $scope.assessmentCompleted = (assessment) => {
    return assessment.participant && assessment.participant.completed;
  };

  $q.all([
    TemplateService.getAll(),
    AssessmentService.getUserSelfAssessments(),
  ]).then(function(res) {
    setupAssessments(res[0], res[1]);

    $scope.data.loaded = true;
  });

  function setupAssessments(templates, assessments) {
    $scope.data.assessments = $scope.data.section.assessments.map(sa => {
      const assessment = assessments.find(aa => aa.guid === sa.id);
      const disabled = sa.disabled && (!assessment || !$scope.assessmentCompleted(assessment));

      return {
        ...templates.find(t => sa.templateGuid === t.guid),
        ...assessment,
        ...sa,
        disabled,
      };
    });
  }
}]);
