angular.module('hcap-analytics').controller('DashboardCtrl', [
  '$scope',
  '$location',
  '$state',
  'SweetAlert',
function(
  $scope,
  $location,
  $state,
  SweetAlert,
) {

  const welcomePopup = () => {
    const firstVisit = localStorage.getItem('firstVisit');
    $scope.welcome = firstVisit !== null ? JSON.parse(firstVisit) : true;

    if ($scope.welcome) {
      localStorage.setItem('firstVisit', false);

      SweetAlert.swal({
        type: 'info',
        customClass: 'welcome-alert',
        title: '',
        text: `Welcome to Hcap Analytics! You may access the assessments in each module via the dashboard on the navigation panel to the left.

We invite first time users to begin in the Self Leadership module, completing popular assessments such as HEXACO Personality Profile and Emotional Intelligence. Next, explore other Hcap capabilities such as Conflict Management Style, Procrastination, and Leading Others.

Welcome again! We hope you enjoy the empowering experience of purposefully developing and showcasing your human capital at work!`
      });
      setTimeout(() => {
        document.querySelector('.sweet-alert').scrollTop = 0;
      }, 50);
    }
  };

  const parser = new UAParser();
  const parseResult = parser.getResult();

  if (!parseResult.browser.name.match(/chrome|firefox/ig)) {
    SweetAlert.swal({
      type: '',
      customClass: 'browser-alert',
      title: 'Unsupported Browser Detected',
      text: `Unsupported browser detected or unable to determine browser.

      Please use Chrome or Firefox`,
    }, () => {
      setTimeout(() => {
        welcomePopup();
      }, 500);
    });
  } else {
    welcomePopup();
  }
}]);
