angular.module('hcap-analytics')
.controller('UserDetailsCtrl', ['$scope','$stateParams','$http','$state','UserService', function($scope,$stateParams,$http,$state,UserService) {

  $scope.data = {};

	UserService.getUserDetails($stateParams.guid, function(response){
    $scope.data.role = response.user.role;
    $scope.data.user = response.user;

		$scope.details = {
			user: JSON.stringify(response.user, null, 4),
			participants: JSON.stringify(response.participants, null, 4),
			designatedRaters: JSON.stringify(response.designatedRaters, null, 4)
		};
	});

  $scope.onRoleChange = role => {
    UserService.setRole($scope.data.user.guid, role).then(() => {
      location.reload();
    });
  };

}]);
