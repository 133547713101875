angular.module('hcap-analytics')
.controller('ParticipantCtrl', ['$scope','$http','$state','$stateParams','AlertService','ParticipantService', function($scope,$http,$state,$stateParams,AlertService,ParticipantService) {

	$scope.info=null
	$scope.error=null
	$scope.loading=true
	$scope.pageIndex=parseInt($stateParams.pageIndex)

	$scope.responses={}

	setTimeout(function(){
		ParticipantService.get($stateParams.participantId, null, function(info){
			if(!info.success) {
				$scope.error=info.message
				$scope.info=null
				$scope.responses={}
			} else {
				$scope.info=info
				$scope.responses=info.responses
				$scope.error=null
				if($scope.pageIndex==0 && $scope.excludePage($scope.pageIndex)) {
					$scope.onClickNextPage()
				}
				if($scope.info.completed && $scope.info.history) {
					$scope.loadGraphs()
				}
			}
			$scope.loading=false
		})
	})

	$scope.onResponse=function(scoring_key,scoring_key_nested){
		var value=$scope.responses[scoring_key]
		if(scoring_key_nested!==undefined && scoring_key_nested!==null) {
			value=value[scoring_key_nested]
		}
		ParticipantService.saveResponse($stateParams.participantId,scoring_key,scoring_key_nested,value,function(ret){
			if(!ret || !ret.success) {
				AlertService.warning("Your response was not saved. Please refresh the page and try again. If this problem persists, please contact support@hcap-analytics.com.","Server Error")
			}
		})
	}

	$scope.onClickPrevPage=function() {
		var destinationPageIndex=$scope.pageIndex-1
		if(destinationPageIndex<0) {
			destinationPageIndex=0
		}
		if($scope.excludePage(destinationPageIndex)) {
			destinationPageIndex--
			if(destinationPageIndex<0) {
				destinationPageIndex=0
			}
		}

		$state.go('participant',{pageIndex:destinationPageIndex})
	}

	$scope.onClickNextPage=function() {
		var destinationPageIndex=$scope.pageIndex+1
		if(destinationPageIndex<$scope.info.pages.length) {
			if($scope.excludePage(destinationPageIndex)) {
				destinationPageIndex++
				if(destinationPageIndex>$scope.info.pages.length-1) {
					$scope.finalizeResponses()
					return
				}
			}
		} else {
			$scope.finalizeResponses()
			return
		}
		$state.go('participant',{pageIndex:destinationPageIndex})
	}

	$scope.finalizeResponses=function(){
		ParticipantService.finalizeResponses($stateParams.participantId,function(ret){
			if(ret.success){
				//$scope.info.completed=true
				location.reload()
			} else {
				AlertService.warning("Error",ret.message)
			}
		})
	}

	$scope.getDisplayGrade=function(num) {
		var conversion={
			"4.3":"A+",
			"4.0":"A",
			"3.7":"A-",
			"3.3":"B+",
			"3.0":"B",
			"2.7":"B-",
			"2.3":"C+",
			"2.0":"C",
			"1.7":"C-",
			"1.3":"D+",
			"1.0":"D",
			"0.0":"F",
			"N/A":"N/A"
		}
		return conversion[num]
	}

	$scope.excludePage=function(pageIndex) {
		if(!pageIndex) {
			pageIndex=$scope.pageIndex
		}
		var seriesExclusionList=$scope.info.pages[pageIndex].exclude_in_series
		if(seriesExclusionList && seriesExclusionList.indexOf($scope.info.seriesIndex)!=-1) {
			return true
		}

		var groupExclusionList=$scope.info.pages[pageIndex].exclude_in_groups
		if(groupExclusionList && groupExclusionList.indexOf($scope.info.group)!=-1) {
			return true
		}

		return false
	}

	$scope.showGoalFeedback=function() {
		if($scope.info.seriesIndex=="1") {
			return false
		}
		var groupExclusionList=["1","3"]
		if(groupExclusionList.indexOf($scope.info.group)==-1) {
			return true
		}
		return false
	}

	$scope.showWellBeingFeedback=function() {
		if($scope.info.seriesIndex=="1") {
			return false
		}
		var groupExclusionList=["1","2"]
		if(groupExclusionList.indexOf($scope.info.group)==-1) {
			return true
		}
		return false
	}

	$scope.loadGraphs=function() {
		$scope.loadGradeGraph()
		$scope.loadClassGoalGraphs()
		$scope.loadGhqGraph()
		$scope.loadSleepGraph()
		$scope.loadHabitGraph()
		$scope.loadPsqiGraph()
	}

	$scope.loadGradeGraph=function() {
		var grades = {
			labels: ["Week 2", "Week 4", "Week 6", "Week 8", "Week 10", "Week 12", "Week 14"],
			series: [],
			data: [],
			options:$scope.goalProgressionOptions
		}
		grades.series=_.values(_.get($scope.info,'history["1"].courses',[]))
		grades.series.forEach(function(course,courseIndex){
			var list=[]
			grades.labels.forEach(function(label,labelIndex){
				var seriesIndex=labelIndex+1
				var grade=_.get($scope.info,'history['+seriesIndex+'].goal_progress['+courseIndex+'].current_course_grade','N/A')
				list.push(grade)
			})
			grades.data[courseIndex]=list
		})
		$scope.grades=grades
	}

	$scope.loadClassGoalGraphs=function() {
		var courseList=_.values(_.get($scope.info,'history["1"].courses',[]))
		$scope.classGoals=[]
		courseList.forEach(function(course,courseIndex){
			var courseGoal = {
				name: course+' Goal Progression',
				labels: ["Week 2", "Week 4", "Week 6", "Week 8", "Week 10", "Week 12", "Week 14"],
				series: ["Current","Minimum Goal","Desired Goal"],
				data: [[],[],[]],
				options:$scope.goalProgressionOptions,
				override:[{borderWidth:5,pointBorderWidth:1,pointBorderColor:"#ffffff"}]
			}
			courseGoal.labels.forEach(function(label,labelIndex){
				var seriesIndex=labelIndex+1
				var actualGrade=_.get($scope.info,'history['+seriesIndex+'].goal_progress['+courseIndex+'].current_course_grade','N/A')
				var minimumGrade=_.get($scope.info,'history['+seriesIndex+'].goal_setting_low['+courseIndex+']','N/A')
				var desiredGrade=_.get($scope.info,'history['+seriesIndex+'].goal_setting_high['+courseIndex+']','N/A')
				courseGoal.data[0].push(actualGrade)
				courseGoal.data[1].push(minimumGrade)
				courseGoal.data[2].push(desiredGrade)
			})
			$scope.classGoals.push(courseGoal)
		})
	}

	$scope.loadGhqGraph=function() {
		var ghq = {
			labels: ["Week 2", "Week 4", "Week 6", "Week 8", "Week 10", "Week 12", "Week 14"],
			series: ["Current","Depressed/anxious individuals","Non-depressed/anxious individuals"],
			data: [[],[],[]],
			options:$scope.ghqProgressionOptions,
			override:[{borderWidth:5,pointBorderWidth:1,pointBorderColor:"#ffffff"}]
		}
		var negativelyScoredIndices=[1,4,5,8,9,10]
		ghq.labels.forEach(function(label,labelIndex){
			var seriesIndex=labelIndex+1
			if(!$scope.info.history || $scope.info.history.hasOwnProperty(seriesIndex)==false) {
				ghq.data[0].push(null)
				ghq.data[1].push(null)
				ghq.data[2].push(null)
				return
			}
			var total=0
			for(var i=0;i<12;i++) {
				var val=parseInt(_.get($scope.info,'history['+seriesIndex+'].ghq12_'+i,0))
				if(negativelyScoredIndices.indexOf(val)!=-1) {
					val=val*-1
				}
				total+=val
			}
			ghq.data[0].push(total)
			ghq.data[1].push(9.79)
			ghq.data[2].push(21.8)
		})
		var currentSeriesIndex=parseInt($scope.info.seriesIndex)
		var currentVal=ghq.data[0][currentSeriesIndex-1]
		var previousSeriesIndex=currentSeriesIndex-1
		if(previousSeriesIndex>=0) {
			var prevValue=ghq.data[0][previousSeriesIndex-1]
			if(prevValue>currentVal) {
				ghq.worse=true
			} else if(prevValue<currentVal) {
				ghq.better=true
			}
		}
		var currentVal=ghq.data[0][currentSeriesIndex-1]
		if(currentVal!==null && currentVal<=9.79) {
			ghq.depressedAnxious=true
		}
		$scope.ghq=ghq
	}

	$scope.loadSleepGraph=function() {
		var sleep = {
			labels: ["Week 2", "Week 4", "Week 6", "Week 8", "Week 10", "Week 12", "Week 14"],
			series: ["Hours of Sleep"],
			data: [[]],
			options:$scope.sleepOptions,
			override:[{borderWidth:5,pointBorderWidth:1,pointBorderColor:"#ffffff"}]
		}
		sleep.labels.forEach(function(label,labelIndex){
			var seriesIndex=labelIndex+1
			var hours=_.get($scope.info,'history['+seriesIndex+'].psqi_hours_asleep')
			sleep.data[0].push(hours)
		})
		$scope.sleep=sleep
	}

	$scope.loadHabitGraph=function() {
		var habit = {
			labels: ["Week 2", "Week 4", "Week 6", "Week 8", "Week 10", "Week 12", "Week 14"],
			series: ["Habit Strength"],
			data: [[]],
			options:$scope.habitOptions,
			override:[{borderWidth:5,pointBorderWidth:1,pointBorderColor:"#ffffff"}]
		}
		habit.labels.forEach(function(label,labelIndex){
			var seriesIndex=labelIndex+1
			var var1=parseInt(_.get($scope.info,'history['+seriesIndex+'].behavioural_frequency'))
			var var2=parseInt(_.get($scope.info,'history['+seriesIndex+'].location_of_behaviour'))
			var var3=parseInt(_.get($scope.info,'history['+seriesIndex+'].time_of_day'))
			habit.data[0].push(var1*var2*var3)
		})
		$scope.habit=habit
	}

	$scope.loadPsqiGraph=function() {
		var psqi = {
			labels: ["Week 2", "Week 4", "Week 6", "Week 8", "Week 10", "Week 12", "Week 14"],
			series: ["Overall Sleep Quality","Good Sleep Quality"],
			data: [[],[]],
			options:$scope.psqiOptions,
			override:[{borderWidth:5,pointBorderWidth:1,pointBorderColor:"#ffffff"}]
		}
		psqi.labels.forEach(function(label,labelIndex){
			var seriesIndex=labelIndex+1
			var q1=parseTime(_.get($scope.info,'history['+seriesIndex+'].psqi_bed_time'))
			var q2=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_minutes_to_sleep'))
			var q3=parseTime(_.get($scope.info,'history['+seriesIndex+'].psqi_wakeup_time'))
			var q4=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_hours_asleep'))
			var q5a=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_a'))
			var q5b=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_b'))
			var q5c=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_c'))
			var q5d=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_d'))
			var q5e=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_e'))
			var q5f=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_f'))
			var q5g=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_g'))
			var q5h=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_h'))
			var q5i=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_trouble_i'))
			var q5j=0
			var q5=q5b+q5c+q5d+q5e+q5f+q5g+q5h+q5i+q5j
			var q6=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_quality'))
			var q7=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_medication'))
			var q8=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_staying_awake'))
			var q9=parseInt(_.get($scope.info,'history['+seriesIndex+'].psqi_enthusiasm'))

			var durat=0
			if(q4==6) {	durat=1	}
			if(q4==5) {	durat=2	}
			if(q4<5) { durat=3 }

			var distb=0
			if(q5>=1 && q5<=9) { distb=1 }
			if(q5>=10 && q5<=18) { distb=2 }
			if(q5>18) { distb=3 }

			var laten=0
			if(q2>15 && q2<=30) { laten=1 }
			if(q2>30 && q2<=60) { laten=2 }
			if(q2>60) { laten=3 }
			if(q5a+laten==0) { laten=0 }
			else if(q5a+laten==1 || q5a+laten==2) { laten=1 }
			else if(q5a+laten==3 || q5a+laten==4) { laten=2 }
			else if(q5a+laten==5 || q5a+laten==6) { laten=3 }

			var daydys=0
			if(q8+q9==0) { daydys=0 }
			else if(q8+q9==1 || q8+q9==2) { daydys=1 }
			else if(q8+q9==3 || q8+q9==4) { daydys=2 }
			else if(q8+q9==5 || q8+q9==6) { daydys=3 }

			var hse=0
			var hseDiff=Math.abs(q3-q1)
			if(hseDiff>24) { hse=hseDiff-24 }
			if(hseDiff<=24) { hse=hseDiff }
			hse=(q4/hse)*100
			if(hse>=85) { hse=0 }
			else if(hse<85 && hse>=75) { hse=1 }
			else if(hse<75 && hse>=65) { hse=2 }
			else { hse=3 }

			var slpqual=q6
			var meds=q7
			var total=durat+distb+laten+daydys+hse+slpqual+meds

			psqi.data[0].push(21-total)
			psqi.data[1].push(16)
		})
		$scope.psqi=psqi

		function parseTime(str) {
			if(!str) {
				return 0
			}
			var h=parseInt(str.substr(0,2))
			var m=parseInt(str.substr(2))
			var minutes=(h*60)+m
			return minutes/60
		}
	}

	$scope.psqiOptions={
		legend: {
			display: true,
			position: 'right'
		},
		showLines: true,
		elements:
		{
			line: {
				fill:false,
				cubicInterpolationMode:'monotone'
			}
		},
		scales: {
			yAxes: [
				{
					id: 'y-axis-1',
					type: 'linear',
					position: 'left',
					ticks: {
						min:0,
						max:21,
						stepSize:1
					}
				}
			]
		}
	}

	$scope.habitOptions={
		legend: {
			display: true,
			position: 'right'
		},
		showLines: true,
		elements:
		{
			line: {
				fill:false,
				cubicInterpolationMode:'monotone'
			}
		},
		scales: {
			yAxes: [
				{
					id: 'y-axis-1',
					type: 'linear',
					position: 'left',
					ticks: {
						min:0,
						max:36,
						stepSize:4
					}
				}
			]
		}
	}

	$scope.sleepOptions={
		legend: {
			display: true,
			position: 'right'
		},
		showLines: true,
		elements:
		{
			line: {
				fill:false,
				cubicInterpolationMode:'monotone'
			}
		},
		scales: {
			yAxes: [
				{
					id: 'y-axis-1',
					type: 'linear',
					position: 'left',
					ticks: {
						min:0,
						suggestedMax:10
					}
				}
			]
		}
	}

	$scope.goalProgressionOptions={
		legend: {
			display: true,
			position: 'right'
		},
		showLines: true,
		elements:
		{
			line: {
				fill:false,
				cubicInterpolationMode:'monotone'
			}
		},
		scales: {
			yAxes: [
				{
					id: 'y-axis-1',
					type: 'linear',
					position: 'left',
					ticks: {
						callback: function(value, index, values) {
							var displayValues={
								'4.3':'A+',
								'4':'A',
								'3':'B',
								'2':'C',
								'1':'D',
								'0':'F'
							}
							return displayValues[value]
						},
						min:0,
						max:4.3,
						stepSize:1.0
					}
				}
			]
		}
	}

	$scope.ghqProgressionOptions={
		legend: {
			display: true,
			position: 'right'
		},
		showLines: true,
		elements:
		{
			line: {
				fill:false,
				cubicInterpolationMode:'monotone'
			}
		},
		scales: {
			yAxes: [
				{
					id: 'y-axis-1',
					type: 'linear',
					position: 'left',
					ticks: {
						min:0,
						max:25,
						stepSize:5
					}
				}
			]
		}
	}
}]);
