angular.module('hcap-analytics').controller('SignUpCtrl', [
  '$scope',
  'UserService',
  'StateService',
  'AlertService',
  '$http',
  '$state',
  '$location',
function(
  $scope,
  UserService,
  StateService,
  AlertService,
  $http,
  $state,
  $location,
) {

    $scope.errorMessage = null;
    $scope.isSignUpDisabled = false;

    $scope.user = {
      name: null,
      email: null,
      password: null,
      password_confirm: null,
    };

    $scope.onEmailChange = function() {
      if (!$scope.user.email) {
        return;
      }
      UserService.validateEmail($scope.user.email)
        .then(function(response) {
          if (!response.exists) {
            $scope.isSignUpDisabled = false;
            $scope.errorMessage = null;
            $scope.showResetPasswordMessage = false;
          } else {
            $scope.isSignUpDisabled = true;
            $scope.errorMessage = response.message;
            if ($scope.errorMessage == 'An account with that email address already exists.') {
              $scope.showResetPasswordMessage = true;
            } else {
              $scope.showResetPasswordMessage = false;
            }
          }


        });
    };

    $scope.signUp = function(valid) {
      if (!valid) {
        return;
      }
      $scope.isSignUpDisabled = true;
      $scope.errorMessage = null;
      UserService.signUp($scope.user, function(user, errorMessage) {
        $scope.isSignUpDisabled = false;
        if (!user) {
          $scope.errorMessage = errorMessage;
        } else {
          StateService.land();
        }
      });
    };
  }]);
