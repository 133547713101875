angular.module('hcap-analytics')
.controller('ProfileCtrl', ['$scope','$http','$state','UserService','$stateParams','$window', 'Util', 'TemplateService', function($scope,$http,$state,UserService,$stateParams,$window,Util,TemplateService) {

    $scope.profile = null;
    $scope.consentText = null;
    $scope.showContinue = $stateParams.require || $stateParams.promptFill;
    $scope.promptFill = $stateParams.promptFill;

    init();

    UserService.getProfile().then(function(profile) {
        $scope.profile = profile;
        // $scope.profile.dob = {
        //     year: moment(profile.date_of_birth).year(),
        //     month: moment(profile.date_of_birth).format('MMMM'),
        //     date: moment(profile.date_of_birth).date()
        // };
    });

    UserService.getConsentText(function(text) {
        $scope.consentText = text;
    })

    $scope.onContinue = async function() {
      $window.scrollTo(0, 0);

      $state.go('home');
    }

    $scope.save = function() {
        UserService.saveProfile($scope.profile)
    }

    function init() {
        // $scope.months = [
        //     "January",
        //     "February",
        //     "March",
        //     "April",
        //     "May",
        //     "June",
        //     "July",
        //     "August",
        //     "September",
        //     "October",
        //     "November",
        //     "December"
        // ];

        $scope.years = [];
        var currentYear = moment().year();
        for(var i=0; i < 100; i++) {
            $scope.years.push(currentYear);
            currentYear--;
        }

        // $scope.days = [];
        // for(var i=1; i <= 31; i++) {
        //     $scope.days.push(i);
        // }
    }

}])
