angular.module('hcap-analytics')
.controller('RootCtrl', [
  '$scope',
  'UserService',
  '$state',
function (
  $scope,
  UserService,
  $state,
) {
  $scope.data = {
    showNavbar: false,
  };

  $scope.$watch(() => {
    const src = !localStorage.getItem('src');
    return [src, $state.$current && $state.$current.self.authenticate];
  }, newVal => {
    $scope.data.showNavbar = newVal[0] && newVal[1];
  }, true);
}]);
