angular.module('hcap-analytics')
  .controller('SignInCtrl', ['$scope', 'UserService', 'StateService', function($scope, UserService, StateService) {
    $scope.errorMessage = null;
    $scope.isSignInDisabled = false;

    $scope.user = {
      email: null,
      password: null,
    };

    $scope.signIn = function(valid) {
      if (!valid) {
        return;
      }
      $scope.isSignInDisabled = true;
      $scope.errorMessage = null;
      UserService.signIn($scope.user, function(user, errorMessage) {
        $scope.isSignInDisabled = false;
        if (!user) {
          $scope.errorMessage = errorMessage;
        } else {
          setTimeout(() => window.prod && LogRocket && LogRocket.identify(user.guid));
          StateService.land();
        }
      });
    };
  }]);
