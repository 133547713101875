angular.module('hcap-analytics')
.controller('TemplateListCtrl', ['$scope', '$http', '$state', 'AlertService', 'TemplateService', '$rootScope', function($scope,$http,$state,AlertService,TemplateService,$rootScope) {

	$scope.templates = null;
	$scope.showArchived = false;

	$scope.getAll = function() {
    TemplateService.getAll().then(templates => {
      $scope.templates = templates;
    });
	}

	$scope.createTemplate = function() {
		TemplateService.create(function(template) {
			$state.go('templateEdit',{ guid: template.guid });
		})
	}

	$scope.getAll();
}]);
