angular.module('hcap-analytics')
.controller('CohortsCtrl', [
  '$scope',
  'CohortsService',
  '$uibModal',
function(
  $scope,
  CohortsService,
  $uibModal,
) {

  $scope.data = {
    cohorts: [],
    introHide: true,
  };

  const setup = force => {
    return CohortsService.getAll(force).then(cohorts => {
      $scope.data.cohorts = cohorts;
    });
  };

  setup();

  $scope.onCreate = () => {
    $uibModal.open({
      templateUrl: 'templates/partials/_createCohort.html',
      controller: ['$uibModalInstance', '$scope', ($uibModalInstance, $scope) => {
        $scope.data = {
          form: null,
          name: null,
        };

        $scope.submit = () => {
          if ($scope.data.form.$valid) {
            $scope.data.form.$setSubmitted();

            const cohort = {
              name: $scope.data.name,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            };

            CohortsService.createCohort(cohort).then(() => {
              setup(true);
              $uibModalInstance.close();
            });
          } else {
            document.querySelector('input').reportValidity();
          }
        };

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };
      }],
    });
  };

  $scope.onDelete = cid => {
    return CohortsService.deleteCohort(cid).then(() => {
      return setup();
    });
  };

  $scope.onUpdate = cid => {
    const cohort = _.cloneDeep($scope.data.cohorts.find(c => c._id === cid));
    const onDelete = $scope.onDelete;

    $uibModal.open({
      templateUrl: 'templates/partials/_updateCohort.html',
      controller: ['$uibModalInstance', '$scope', ($uibModalInstance, $scope) => {
        $scope.data = {
          form: null,
          cohort,
        };

        $scope.submit = () => {
          if ($scope.data.form.$valid) {
            $scope.data.form.$setSubmitted();

            CohortsService.updateCohort(cohort).then(() => {
              setup();
              $uibModalInstance.close();
            });
          } else {
            document.querySelector('input').reportValidity();
          }
        };

        $scope.deleteCohort = () => {
          onDelete(cid).then($uibModalInstance.close);
        };

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };
      }],
    });
  };

  $scope.toggleIntro = () => {
    $scope.data.introHide = !$scope.data.introHide;
  };

}]);
