angular.module('hcap-analytics')
  .service('CohortsService', ['$http', '$q', '$location', function($http, $q, $location) {
    let cohorts = [];
    let users = {};
    let summary = null;
    let skills = {};

    const service = {
      clearCache: () => {
        cohorts = [];
        users = {};
        summary = null;
      },
      getAll: force => {
        if (!force && cohorts.length > 0) {
          return $q.resolve(_.cloneDeep(cohorts));
        } else {
          return $http.get('./api/cohorts').then(res => {
            cohorts = res.data;
            cohorts.forEach(c => {
              c.phases = c.phases.map(p => ({
                ...p,
                dueDate: new Date(p.dueDate),
                startDate: new Date(p.startDate),
              }));
            });

            return _.cloneDeep(cohorts);
          });
        }
      },
      getId: (id, force) => {
        const d = $q.defer();
        if (cohorts.length === 0) {
          service.getAll().then(d.resolve);
        } else {
          d.resolve();
        }

        return d.promise.then(() => {
          const existing = cohorts.find(c => c._id === id);
          if (existing && !force) {
            return $q.resolve(_.cloneDeep(existing));
          } else {
            return $http.get('./api/cohorts/' + id).then(res => {
              const cohort = res.data;
              const cacheIndex = cohorts.findIndex(c => c._id === cohort._id);
              cohort.phases = cohort.phases.map(p => ({
                ...p,
                dueDate: new Date(p.dueDate),
                startDate: new Date(p.startDate),
              }));

              if (cacheIndex > -1) {
                cohorts.splice(cacheIndex, 1, cohort);
              } else {
                cohorts.push(cohort);
              }

              return _.cloneDeep(cohort);
            });
          }
        });
      },
      getUsers: (cid, force) => {
        if (users[cid] && !force) {
          return $q.resolve(_.cloneDeep(users[cid]));
        } else {
          return $http.get('./api/cohorts/' + cid + '/users').then(res => {
            users[cid] = res.data;

            return _.cloneDeep(users[cid]);
          });
        }
      },
      summary: (force) => {
        if (summary && !force) {
          return $q.resolve(_.cloneDeep(summary));
        } else {
          return $http.get('./api/cohortSummary').then(res => {
            summary = res.data.map(c => ({
              ...c,
              phases: c.phases.map(p => ({
                ...p,
                dueDate: new Date(p.dueDate),
                startDate: new Date(p.startDate),
              })),
            }));

            return _.cloneDeep(summary);
          });
        }
      },
      createCohort: cohortData => {
        return $http.post('./api/cohorts', cohortData).then(res => {
          const cid = res.data;

          cohorts.push({
            _id: cid,
            ...cohortData,
          });
        });
      },
      updateCohort: cohort => {
        const id = cohort._id;

        return $http.put('./api/cohorts/' + id, cohort).then(res => {
          const cohort = res.data;
          cohort.phases = cohort.phases.map(p => ({
            ...p,
            dueDate: new Date(p.dueDate),
            startDate: new Date(p.startDate),
          }));

          cohorts.splice(cohorts.findIndex(c => c._id === id), 1, cohort);
        });
      },
      toggleLeaders: (id, state) => {
        return $http.post(`./api/cohorts/${id}/leaders`, { state }).then(res => {
          const cohort = cohorts.find(c => c._id === id);
          cohort.teamLeaders = state;
          cohort.phases = res.data.map(p => ({
            ...p,
            dueDate: new Date(p.dueDate),
            startDate: new Date(p.startDate),
          }));
        });
      },
      setTeamLeader: (cohortId, teamId, leaderId) => {
        return $http.post(`./api/cohorts/${cohortId}/teams/${teamId}/leader`, { leaderId }).then(() => {
          const cohort = cohorts.find(c => c._id === cohortId);
          cohort.teams.find(t => t.id === teamId).leaderId = leaderId;
        });
      },
      addPhase: (id, type) => {
        return $http.post(`./api/cohorts/${id}/phases`, { type }).then(() => {
          return service.getId(id, true);
        });
      },
      deletePhase: (id, index) => {
        return $http.delete(`./api/cohorts/${id}/phases/${index}`).then(() => {
          cohorts[cohorts.findIndex(c => c._id === id)].phases.splice(index, 1);
        });
      },
      deleteCohort: id => {
        return $http.delete('./api/cohorts/' + id).then(() => {
          cohorts.splice(cohorts.findIndex(c => c._id === id), 1);
        });
      },
      uploadBulk: (cid, participants) => {
        return $http.post(`./api/cohorts/${cid}/users`, participants).then(() => {
          return $q.all([
            service.getId(cid, true),
            service.getUsers(cid, true),
          ]);
        });
      },
      skills: (cid, tid, force, cache = true, orgView = false) => {
        if (!force && skills[cid]) {
          return $q.resolve(_.cloneDeep(skills[cid]));
        } else {
          return $http.get(`./api/cohorts/${cid}/teams/${tid}/skills?orgView=${orgView}`).then(res => {
            if (cache) {
              skills[cid] = res.data;
            }
            return _.cloneDeep(res.data);
          });
        }
      },
      updateCharter: (cid, charter) => {
        return $http.post(`./api/cohorts/${cid}/charter`, {
          charter,
        });
      },
      confirmCharter: (cid, val) => {
        return $http.post(`./api/cohorts/${cid}/charter/confirm`, {
          state: val,
        });
      },
      teamPartData: (cid, tid) => {
        return $http.get(`/api/cohorts/${cid}/teamPartData/${tid}`).then(res => {
          return res.data;
        });
      },
      removeUser: (cid, uid) => {
        return $http.delete(`/api/cohorts/${cid}/users/${uid}`).then(res => {
          const index = users[cid].findIndex(u => u._id === uid);
          if (index !== -1) {
            users[cid].splice(index, 1);
          }
        });
      },
      finalize: cohortId => {
        return $http.post(`./api/cohorts/${cohortId}/finalize`).then(res => {
          const cohort = cohorts.find(c => c._id === cohortId);
          if (cohort) {
            cohort.finalized = true;
          }
        });
      },
      addTeam: cid => {
        return $http.post(`./api/cohorts/${cid}/teams`);
      },
    };

    return service;
  }]);
