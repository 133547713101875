angular.module('hcap-analytics').controller('InvitesCtrl', [
  '$scope',
  '$state',
  'InvitesService',
function(
  $scope,
  $state,
  InvitesService,
) {
  $scope.taggedAssessments = [];
  $scope.unfinishedPhases = [];
  $scope.startCase = _.startCase;

  $scope.$watch(() => InvitesService.noticeCount, newVal => {
    $scope.taggedAssessments = InvitesService.taggedAssessments;
    $scope.unfinishedPhases = InvitesService.unfinishedPhases;
  });

  $scope.goInvite = assessment => {
    $state.go('participate.questions', {
      templateId: assessment.template_guid,
      participantId: assessment.participant.guid,
      finishRoute: 'participate.rater-finish',
      onFinish: InvitesService.update,
      asRater: true,
    });
  };

  $scope.goPhase = phase => {
    $state.go('team', {
      id: phase.cohortId,
      phase: phase.phaseIndex,
    });
  };
}]);
