angular.module('hcap-analytics')
.controller('AssessmentListCtrl', ['$scope', '$http', '$state', 'AlertService', 'AssessmentService', function($scope,$http,$state,AlertService,AssessmentService) {

	$scope.assessments = null
	$scope.showHidden = false

	$scope.createAssessment = function() {
		AssessmentService.create()
		.then(function(guid) {
			if(guid) {
				location.href = 'v1/assessment/'+guid+'/setup'
			}
		})
	}

	$scope.getAll = function() {
		AssessmentService.getAll(function(assessments){
			assessments.forEach(function(asssessment){
				asssessment.questions = asssessment.questions || [];
			})
			$scope.assessments = assessments
		})
	}

	$scope.onClickHide = function(asssessment) {
		AssessmentService.hide( asssessment.guid, function(success){
			$scope.getAll()
		})
	}

	$scope.onClickShow = function(asssessment) {
		AssessmentService.unhide(asssessment.guid, function(success){
			$scope.getAll()
		})
	}

	$scope.getAll()
}]);
