angular.module('hcap-analytics')
.controller('GroupCtrl', ['$scope','$http','$state','AlertService','GroupService', function($scope,$http,$state,AlertService,GroupService) {

  $scope.guid = $state.params.guid;
	$scope.group = null;
	$scope.newEntry = { };
	$scope.addUsersToGroupDangerMessage = null;
	$scope.addUsersToGroupWarningMessage = null;
	$scope.addUsersToGroupMessage = null;

  $scope.dataContext = { groupId: $state.params.guid };

	$scope.get = function(guid) {
		GroupService.get(guid, function(group) {
			$scope.group = group
		})
	}

	$scope.addEntry=function(){

		if (!$scope.newEntry.name || $scope.newEntry.name.length == 0) {
			$scope.addUserToGroupMessage = "Missing name"
			return;
		}
		if (!$scope.newEntry.email || $scope.newEntry.email.length == 0) {
			$scope.addUserToGroupMessage = "Missing email"
			return;
		}

		$scope.newEntry.email = $scope.newEntry.email.toLowerCase();

		for (var i = 0; i < $scope.group.list.length; i++) {
			if ($scope.group.list[i].email.toLowerCase() == $scope.newEntry.email) {
				$scope.addUserToGroupMessage = "Duplicate email: " + $scope.newEntry.email;
				return;
			}
		}

		$scope.addUserToGroupMessage = null;
		$scope.group.list.push(_.clone($scope.newEntry));
		$scope.group.list.sort(function(a, b) {
			return a.name - b.name;
		});

		GroupService.update($scope.group.guid, $scope.group.name, $scope.group.list).then(function(ret) {
			if(ret.success) {
				$scope.newEntry = { };
				$scope.group = ret.group;
			} else {
				AlertService.warning("Unable to add user to group",ret.message)
			}
		})

	}

	$scope.read = function (workbook) {
		var contents=to_json(workbook)
		var entries=[]
		for(var sheetName in contents) {
			var sheetData=contents[sheetName]
			sheetData.forEach(function(row){
				var entry={
					name: row.Name,
					email: row.Email.toLowerCase(),
				}
				if(!entry.name && row['First Name'] && row['Last Name']) {
					entry.name=row['First Name']+' '+row['Last Name']
				}
				entries.push(entry)
			})
		}
		if(entries.length==0) {
			$scope.addUsersToGroupDangerMessage = "No valid rows found in the file.";
			return
		}

		var newList = _.cloneDeep($scope.group.list);
		var ignoredEmails = [];

		var intersection = _.intersectionBy(entries, $scope.group.list, 'email');
		if (intersection.length > 0) {
			ignoredEmails = _.map(intersection, 'email');
			$scope.addUsersToGroupWarningMessage = "Ignored duplicate(s): " + ignoredEmails.join(', ');
		}

		newList = _.concat(newList, entries);
		newList = _.uniqBy(newList, 'email');
		newList.sort();

		GroupService.update($scope.group.guid, $scope.group.name, newList).then(function(ret) {
			if(ret.success) {
				$scope.group = ret.group;
			} else {
				AlertService.warning("Unable to add users to group",ret.message)
			}
		})
	}

	$scope.error = function (e) {
		console.log(e)
		AlertService.warning("Unable read file","Error")
	}

	$scope.deleteEntry = function(index) {
		$scope.group.list.splice(index, 1);
		GroupService.update($scope.group.guid, $scope.group.name, $scope.group.list).then(function(ret) {
			if(ret.success) {
				$scope.group = ret.group;
			} else {
				AlertService.warning("Unable to delete entry from group",ret.message)
			}
		})
	}

	$scope.onUpdateName = function(index) {
		GroupService.update($scope.group.guid, $scope.group.name, $scope.group.list).then(function(ret) {
			if(ret.success) {
				// $scope.group = ret.group;
			} else {
				AlertService.warning("Unable to delete entry from group",ret.message)
			}
		})
	}

	function to_json(workbook) {
		var result = {}
		workbook.SheetNames.forEach(function(sheetName) {
			var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
			if(roa.length > 0){
				result[sheetName] = roa
			}
		})
		return result
	}

	$scope.get($state.params.guid)
}]);
