angular.module('hcap-analytics')
.controller('TeamCtrl', [
  '$scope',
  '$q',
  '$http',
  '$window',
  '$timeout',
  '$state',
  'CohortsService',
  'Util',
  'AssessmentService',
  'ParticipantService',
  'UserService',
function(
  $scope,
  $q,
  $http,
  $window,
  $timeout,
  $state,
  CohortsService,
  Util,
  AssessmentService,
  ParticipantService,
  UserService,
) {
  // if changing assessmentId, ensure all instances in codebase thoroughly inspected
  const skillIdMap = {
    //hexaco: '92Ob1BX3Gj5fxXeOCb1J7V',
    hexaco: 'y9ixAPySFguyv3Aj5MY3LV',
    procrast: 'tklRdApLIsD2fxW3Sejq2F',
    ei: 'QrOG1AqdFHyh7_fZb0_DEk',
  };

  const cohortId = $state.params.id;
  const initialPhaseIndex = +$state.params.phase;

  let cohortParts;

  const MILESTONE_INDEX = -3;
  const PROGRESS_INDEX = -2;
  const INTRO_INDEX = -1;
  const HIDE_INDEX = -9999;
  const CHARTER_INDEX = -9998;

  $scope.PROGRESS_INDEX = PROGRESS_INDEX;
  $scope.MILESTONE_INDEX = MILESTONE_INDEX;
  $scope.INTRO_INDEX = INTRO_INDEX;
  $scope.HIDE_INDEX = HIDE_INDEX;
  $scope.CHARTER_INDEX = CHARTER_INDEX;

  $scope.data = {
    cohort: null,
    phaseDisplay: null,
    members: null,
    myCompletion: null,
    phaseIndex: INTRO_INDEX,
    phaseDisplayIndex: 0,
    phaseDisplaySubindex: 0,
    skills: null,
    skillsDisplay: null,
    previousIndex: -1,
    loaded: false,
    assessmentLoaded: false,
    showPhaseResults: [],
    noneDisplayed: true,

    charterEditMode: false,
    charter: null,
  };

  $scope.save = _.debounce(() => {
    CohortsService.updateCharter(cohortId, $scope.data.charter);
  }, 750);

  $scope.$watch('data.charter', () => {
    if ($scope.data.charterEditMode) {
      $scope.save();
    }
  }, true);

  $scope.toggleEditCharter = () => {
    $scope.data.charterEditMode = !$scope.data.charterEditMode;
  };

  $scope.charterButtonText = () => {
    if ($scope.data.myCompletion[0].charter) {
      return 'Team Charter Affirmed';
    } else {
      return 'Confirm your Commitment to the Team Charter';
    }
  };

  $scope.participateData = {
    templateId: null,
    participantId: null,
  };

  $scope.context = {
    goDevPlan: true,
    restrictTo: [
      'TMEF',
      'CTTW',
      'ITTM',
      'KETT',
      'EXQU',
      'RKSA',
      'LDPO',
      'LEAD_TMEF',
      'LEAD_PMEF',
    ],
  };

  const loadData = (force) => {
    return $q.all([
      AssessmentService.getUserSelfAssessments(force),
      CohortsService.summary(force),
    ]).then(results => {
      const summary = results[1];
      $scope.data.cohort = summary.find(c => c._id === cohortId);
      $scope.data.charter = $scope.data.cohort.team.charter;
      const teamId = $scope.data.cohort.team.id;

      return CohortsService.skills(cohortId, teamId).then(skills => {
        return [...results, skills];
      });
    }).then(results => {
      const allParticipants = results[0];
      const summary = results[1];
      const skills = results[2];

      const skillIds = Object.keys(skillIdMap).map(k => skillIdMap[k]);
      cohortParts = allParticipants.filter(a => {
        return a.participant.cohortId === cohortId || skillIds.includes(a.participant.assessmentId);
      });
      const userId = UserService.getUser()._id;

      $scope.data.members = $scope.data.cohort.team.members;
      $scope.data.cohort.phases.forEach((p, i) => p.index = i);
      $scope.data.myCompletion = $scope.data.cohort.team.members.find(m => m.id === userId).completion;

      const members = $scope.data.members;
      $scope.data.showPhaseResults = $scope.data.cohort.phases.map((p, i) => {
        return members.reduce((acc, m) => p.type !== 'setup' ? +m.completion[i] + acc : acc, 0) > 2;
      });

      $scope.data.skills = skills;
      $scope.data.noneDisplayed = Object.keys(skills.stats).every(stat => !skills.stats[stat])

      Object.keys(skillIdMap).forEach(s => {
        const assessment = cohortParts.find(a => a.participant.assessmentId === skillIdMap[s]);
        $scope.data.skills[s] = assessment && assessment.participant.completed || false;
      });

      if ($scope.data.skills.stats) {
        $scope.data.skillsDisplay = [];

        if ($scope.data.skills.procrast && $scope.data.skills.stats.procrast) {
          $scope.data.skillsDisplay.push($scope.data.skills.stats.procrast['PRCRST']);
        }

        if ($scope.data.skills.ei && $scope.data.skills.stats.ei) {
          $scope.data.skillsDisplay.push($scope.data.skills.stats.ei['EMOI']);
        }

        if ($scope.data.skills.hexaco && $scope.data.skills.stats.hexaco) {
          const hexacoPart = cohortParts.find(a => a.participant.assessmentId === skillIdMap.hexaco).participant;

          ParticipantService.get(hexacoPart.guid, null, part => {
            const sections = part.scoring.self.sections;
            let order = [
              'WO_CONSCI',
              'WO_AGREE',
            ];

            order = [
              ...order,
              ...sections.filter(s => !order.includes(s.id)).map(s => s.id),
            ];

            $scope.data.skillsDisplay.push(...order.filter(id => {
              return $scope.data.skills.stats.hexaco[id];
            }).map(id => $scope.data.skills.stats.hexaco[id]));
          });
        }
      }

      // build timeline

      const phases = $scope.data.cohort.phases;
      const healthReports = {
        name: 'Team Health Reports',
        type: 'prog',
        index: PROGRESS_INDEX,
        phases: [],
      };

      const milestones = {
        name: 'Project Milestone Evaluations',
        type: 'eval',
        index: MILESTONE_INDEX,
        phases: [],
      };

      $scope.data.phaseDisplay = [{
        name: 'Team Intro',
        index: INTRO_INDEX,
      }];

      phases.forEach((p, index) => {
        if (p.type === 'prog') {
          healthReports.phases.push({
            name: $scope.phaseName(index, phases),
            index: index,
          });
        } else if (p.type === 'eval') {
          milestones.phases.push({
            name: $scope.phaseName(index, phases),
            index: index,
          });
        } else {
          $scope.data.phaseDisplay.push({
            name: $scope.phaseName(index, phases),
            type: p.type,
            index: index,
          });
        }
      });

      $scope.data.phaseDisplay.push(healthReports);
      $scope.data.phaseDisplay.push(milestones);

      if (initialPhaseIndex >= 0) {
        setTimelinePhaseIndex(initialPhaseIndex);
      }

      $scope.data.loaded = true;
    });
  };

  $scope.selectPhaseMain = pIndex => {
    const phaseDisplay = $scope.data.phaseDisplay[pIndex];
    let phaseIndex;

    $scope.data.phaseDisplaySubindex = 0;

    if ([PROGRESS_INDEX, MILESTONE_INDEX].includes(phaseDisplay.index)) {
      phaseIndex = phaseDisplay.phases[$scope.data.phaseDisplaySubindex].index;
    } else {
      phaseIndex = phaseDisplay.index;
    }


    $scope.data.phaseDisplayIndex = pIndex;
    $scope.data.phaseIndex = phaseIndex;

    const phase = $scope.data.cohort.phases[phaseIndex];
    if (!phase) {
      return;
    }

    const assessmentId = phase.assessmentId;

    if (assessmentId) {
      const assessment = cohortParts.find(a => a.guid === assessmentId);
      if (assessment) {
        $scope.setParticipateData(assessment);
      }
    }

    $state.go('team', { id: cohortId });
  };

  $scope.selectSubphase = spIndex => {
    const phaseIndex = $scope.data.phaseDisplay[$scope.data.phaseDisplayIndex].phases[spIndex].index;
    const phase = $scope.data.cohort.phases[phaseIndex];

    $scope.data.phaseDisplaySubindex = spIndex;
    $scope.data.phaseIndex = phaseIndex;

    const assessment = cohortParts.find(a => a.guid === phase.assessmentId);
    if (assessment) {
      $scope.setParticipateData(assessment);
    }

    $state.go('team', { id: cohortId });
  };

  $scope.setParticipateData = assessment => {
    $scope.participateData.participantId = assessment.participant.guid;
    $scope.participateData.templateId = assessment.template_guid;

    $scope.data.assessmentLoaded = false;
    $timeout(() => {
      $scope.data.assessmentLoaded = true;
    }, 0);
  };

  $scope.onSkillStart = id => {
    $scope.onAssessmentAction(skillIdMap[id]);
  };

  $scope.onAssessmentAction = aId => {
    const assessmentId = aId || $scope.data.cohort.phases[$scope.data.phaseIndex].assessmentId;

    $q((resolve, reject) => {
      const assessment = cohortParts.find(a => a.guid === assessmentId);

      if (!assessment) {
        const user = UserService.getUser();

        AssessmentService.addParticipant(assessmentId, {
          userId: user._id,
          name: user.name,
          email: user.email,
          cohortId,
          assessmentId,
        }, false, () => {
          loadData().then(() => {
            resolve(cohortParts.find(a => a.guid === assessmentId));
          });
        });
      } else {
        resolve(assessment);
      }
    }).then(assessment => {
      $scope.setParticipateData(assessment);

      $scope.data.previousIndex = $scope.data.phaseIndex;
      $scope.data.phaseIndex = HIDE_INDEX;

      const participantId = assessment.participant.guid;
      const questionRoute = 'team.questions';

      $state.go(questionRoute, {
        templateId: assessment.template_guid,
        participantId,
        finishRoute: 'team',
        finishRouteParams: {
          id: cohortId,
        },
        teamMembers: $scope.data.members.map(m => _.pick(m, ['id', 'name'])),
        loadRoute: questionRoute,
      }).catch(e => {
        console.log('error', e);
      });
    });
  };

  $scope.onFinishQuestions = () => {
    $scope.data.phaseIndex = $scope.data.previousIndex;
    $window.scrollTo(0, 0);
    return loadData(true);
  };

  $scope.viewCharter = () => {
    $scope.data.phaseIndex = CHARTER_INDEX;
    $timeout(() => {
      $window.scrollTo(0, 0);
    });
  };

  $scope.onConfirmCharter = val => {
    CohortsService.confirmCharter(cohortId, !!val).then(() => {
      return loadData(true);
    });
  };

  $scope.showPhase = index => {
    return index === 0 || new Date() > new Date($scope.data.cohort.phases[index - 1].startDate);
  };

  $scope.phaseName = Util.phaseDisplay;

  $scope.phaseCanEdit = phase => {
    return +phase.startDate <= +new Date() && +phase.dueDate >= +new Date();
  };

  $scope.phaseCanView = phase => {
    return +phase.startDate <= +new Date();
  };

  $scope.assessmentActionText = pi => {
    if ($scope.data.myCompletion[pi]) {
      return 'finished assessment';
    } else {
      return $scope.phaseCanEdit($scope.data.cohort.phases[pi]) ? 'start assessment' : 'assessment expired';
    }
  };

  $scope.print = () => {
    window.print();
  };

  function setTimelinePhaseIndex(phaseIndex) {
    $scope.data.phaseIndex = phaseIndex;
    $scope.data.phaseDisplayIndex = 0;
    $scope.data.phaseDisplaySubindex = 0;

    for (let i = 0; i < $scope.data.phaseDisplay.length; i++) {
      const phaseDisplay = $scope.data.phaseDisplay[i];

      if ([PROGRESS_INDEX, MILESTONE_INDEX].includes(phaseDisplay.index)) {
        for (let j = 0; j < phaseDisplay.phases.length; j++) {
          if (phaseDisplay.phases[j].index === phaseIndex) {
            $scope.data.phaseDisplayIndex = i;
            $scope.data.phaseDisplaySubindex = j;
            return;
          }
        }
      } else {
        if (phaseDisplay.index === phaseIndex) {
          $scope.data.phaseDisplayIndex = i;
          return;
        }
      }
    }
  }

  loadData();
}]);
