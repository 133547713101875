angular.module('hcap-analytics')
  .controller('QuestionsCtrl', ['$scope', '$q', '$http', '$state', '$stateParams', 'AlertService', 'TemplateService', 'localStorageService', '$window', 'ParticipantService', 'UserService', 'AssessmentStatsService', 'Util', function($scope, $q, $http, $state, $stateParams, AlertService, TemplateService, localStorageService, $window, ParticipantService, UserService, AssessmentStatsService, Util) {

    $scope.user = UserService.getUser();
    $scope.templateId = $stateParams.templateId;
    $scope.participantId = $stateParams.participantId;
    $scope.finishRoute = $stateParams.finishRoute;
    $scope.onFinishCb = $stateParams.onFinish;
    $scope.finishRouteParams = $stateParams.finishRouteParams || {};
    $scope.teamMembers = $stateParams.teamMembers;
    $scope.otherMembers = ($stateParams.teamMembers || []).filter(m => m.id !== $scope.user._id);
    $scope.loadRoute = $stateParams.loadRoute;

    $scope.pageIndex = Number($stateParams.pageIndex) || 0;
    $scope.pageCount = null;
    $scope.pageProgressPerentage = 0;
    $scope.itemCount = 0;

    $scope.template = null;
    $scope.assessment = null;
    $scope.participant = null;
    $scope.ready = false;

    $scope.load = function() {
      if ($scope.participantId == 'preview') {
        TemplateService.get($scope.templateId, function(template) {
          $scope.template = template;
          $scope.responses = JSON.parse(localStorageService.get($scope.templateId)) || {};
          loadInternal();
          $scope.ready = true;
        });
      } else if ($scope.participantId == 'preview_designated_rater') {
        TemplateService.get($scope.templateId, function(template) {
          template.questions = template.designatedRaterQuestions;
          $scope.template = template;
          $scope.responses = JSON.parse(localStorageService.get($scope.templateId)) || {};
          loadInternal();
          $scope.ready = true;
        });
      } else {
        AssessmentStatsService.get(data => {
          ParticipantService.get($scope.participantId, null, function(response) {
            $scope.assessment = response.assessment;
            $scope.template = response.template;

            $scope.participant = response.participant;
            $scope.responses = response.participant.responses;
            $scope.responses.leader = response.leader;

            if ($scope.participant.designatedRater) {
              $scope.responses['designatorFullName'] = _.startCase($scope.participant.designatorName);
              $scope.responses['designatorName'] = _.startCase($scope.participant.designatorName.trim().split(' ')[0]);
              $scope.template.questions = $scope.template.designatedRaterQuestions;
            }

            loadInternal();
            $scope.ready = true;
          });
        });
      }
    };

    function loadInternal() {
      let pageIndex = 0;
      $scope.template.questions.forEach(function(question) {
        question.pageIndex = pageIndex;
        if (!question.deprecated) {
          if (question.type == 'page_break') {
            pageIndex++;
          } else if (question.type != 'caption') {
            $scope.itemCount++;
          }
        }
      });
      $scope.pageCount = pageIndex + 1;
      watchResponses();
    }


    function watchResponses() {
      $scope.$watch('responses', function(newVal, oldVal, scope) {
        if ($scope.participantId == 'preview' || $scope.participantId == 'preview_designated_rater') {
          localStorageService.set($scope.templateId, JSON.stringify($scope.responses));
        } else {
          const diff = Object.keys(newVal).reduce((acc, key) => {
            if (!_.isEqual(newVal[key], oldVal[key])) {
              acc[key] = newVal[key];
            }
            return acc;
          }, {});
          //var diff = difference(newVal,oldVal)
          for (const key in diff) {
            ParticipantService.saveResponse($scope.participantId, key, diff[key], function() {});
          }
        }
        $scope.updateCompletionProgress();
        $scope.updateIncompleteItemStatus(false);
      }, true);
    }

    $scope.updateCompletionProgress = function() {
      const responses = _.clone($scope.responses);
      delete responses.designatorFullName;
      const responseCount = Object.keys(responses).length;
      $scope.pageProgressPerentage = Math.min(parseInt( responseCount / $scope.itemCount * 100), 100);
    };

    $scope.updateIncompleteItemStatus = function(showIncomplete) {
      const questionsForPage = $scope.template.questions.filter(function(q) {
        return $scope.isVisible(q) && q.pageIndex == $scope.pageIndex && !$scope.canIgnore(q);
      });

      const keysForPage = questionsForPage.map(function(q) {
        return q.key;
      });

      const keysForMissingResponses = keysForPage.filter(function(key) {
        return $scope.responses.hasOwnProperty(key) == false;
      });

      $scope.template.questions.forEach(function(q) {
        if (keysForMissingResponses.indexOf(q.key) != -1) {
          if (showIncomplete) {
            q.incomplete = true;
          }
        } else {
          delete q.incomplete;
        }
      });
      return keysForMissingResponses;
    };

    $scope.enforcePageCompletion = function() {
      if ($scope.participantId == 'preview' || $scope.participantId == 'preview_designated_rater') {
        return true;
      }
      const keysForMissingResponses = $scope.updateIncompleteItemStatus(true);
      if (keysForMissingResponses.length > 0) {
        AlertService.warning('Please make sure you have completed all the items on the page.');
      }
      return keysForMissingResponses.length == 0;
    };

    $scope.warnPageCompletionAsync = function(callback) {
      if ($scope.participantId == 'preview' || $scope.participantId == 'preview_designated_rater') {
        callback(true);
        return;
      }
      const keysForMissingResponses = $scope.updateIncompleteItemStatus(true);
      if (keysForMissingResponses.length == 0) {
        callback(true);
        return;
      }

      AlertService.confirm2('You have unanswered items on the page.', 'Warning', 'Ignore', 'Go Back', callback);
    };

    $scope.onPreviousPage = function() {
      let newPageIndex = $scope.pageIndex - 1;
      newPageIndex = Math.max(newPageIndex, 0);
      $state.go($scope.loadRoute || 'participate.questions', {
        ...$stateParams,
        pageIndex: newPageIndex,
      }, { notify: false });
      $window.scrollTo(0, 0);
    };

    $scope.onNextPage = function() {
      if ($scope.template.oneOnOne) {
        AlertService.prompt(
          'It is recommended that you add at least 3 employees. Please click on Add Another Employee to add another employee. If you do not want to add another employee, please click on Finish to end the survey.',
          null,
          'Add Another Employee',
          'Finish',
          function(choice) {
            if (choice) {
              goToNextPage();
            } else {
              $scope.onFinish(true);
            }
          }
        );
        return;
      }
      // if(!$scope.enforcePageCompletion()) {
      // 	return;
      // }
      $scope.warnPageCompletionAsync(function(choice) {
        if (!choice) {
          return;
        }
        goToNextPage();
      });

      // var newPageIndex = $scope.pageIndex + 1;
      // newPageIndex = Math.min(newPageIndex, $scope.pageCount - 1);
      // $state.go('participate', { templateId: $scope.templateId, participantId: $scope.participantId, pageIndex:newPageIndex }, { notify: false });
      // $window.scrollTo(0, 0);

      function goToNextPage() {
        let newPageIndex = $scope.pageIndex + 1;
        newPageIndex = Math.min(newPageIndex, $scope.pageCount - 1);
        $state.go($scope.loadRoute || 'participate.questions', {
          ...$stateParams,
          pageIndex: newPageIndex,
        }, { notify: false });
        $window.scrollTo(0, 0);
      }
    };

    $scope.onFinish = function(force) {
      if (force) {
        $scope.goToFinish();
        return;
      }

      $scope.warnPageCompletionAsync(function(choice) {
        if (!choice) {
          return;
        }
        $scope.goToFinish();
      });

    };

    $scope.goToFinish = function() {
      ParticipantService.finalizeResponses($scope.participantId, function(response) {
        let promise = $q.resolve();

        if ($scope.onFinishQuestions) {
          promise = $scope.onFinishQuestions();
        }

        promise.then(() => {
          if ($scope.onFinishCb) {
            $scope.onFinishCb();
          }

          $state.go($scope.finishRoute || 'participate.result', {
            templateId: $scope.templateId,
            participantId: $scope.participantId,
            ...$scope.finishRouteParams,
          });
        });
      });
    };

    $scope.getQuestion = function(question) {
      // var prompt = question.type != 'caption' ? question.displayIndex + '. ' + question.prompt : question.prompt;
      let prompt = question.prompt;

      let startIndex = prompt.indexOf('${');
      let endIndex = prompt.indexOf('}', startIndex);

      while (startIndex != -1 && endIndex != -1 && startIndex < endIndex) {
        const key = prompt.substring(startIndex + 2, endIndex);
        const keyHolder = '${' + key + '}';
        if ( $scope.responses[key] && $scope.responses[key].trim().length > 0 ) {
          prompt = prompt.replace(keyHolder, $scope.responses[key]);
        } else {
          prompt = prompt.replace(keyHolder, '__________________');
        }
        startIndex = prompt.indexOf('${');
        endIndex = prompt.indexOf('}', startIndex);
      }

      return prompt;
    };

    $scope.roundRobinClick = (key, mId, val) => {
      if (!$scope.responses[key]) {
        $scope.responses[key] = {};
      }

      $scope.responses[key][mId] = '' + val;
    };

    $scope.userSelect = (key, mId, max) => {
      if (!$scope.responses[key]) {
        $scope.responses[key] = [];
      }

      const index = $scope.responses[key].indexOf(mId);
      if (index === -1) {
        if ($scope.responses[key].length >= 2) {
          $scope.responses[key].shift();
        }

        $scope.responses[key].push(mId);
      } else {
        $scope.responses[key].splice(index, 1);
      }
    };

    $scope.isVisible = question => {
      if (question.deprecated) {
        return false;
      } else if (question.dependOn) {
        return question.dependOnVals.split(',').includes($scope.responses[question.dependOn]);
      } else {
        return true;
      }
    };

    $scope.canIgnore = Util.canIgnore;

    $scope.load();
  }]);
