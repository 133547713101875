angular.module('hcap-analytics')
.directive('scorebar', [
  function(
  ) {
    return {
      scope: {
        marksRaw: '<marks',
        startRange: '<',
        endRange: '<',
        maxScore: '<',
        legendMarkTexts: '<?',
        legendRangeText: '<?',
        legendSampleText: '<?',
        markTypes: '<?',
      },
      templateUrl: 'templates/scorebar.html',
      restrict: 'E',
      link: function($scope, $element, $attrs) {
        $scope.marks = $scope.marksRaw.map(d => _.round(+d, 1));
        $scope.legendTexts = $scope.legendMarkTexts || [];

        $scope.marks.forEach((m, i) => {
          $scope.legendTexts[i] = $scope.legendTexts[i] || '';
        });

        const typeColorMap = {
          personal: 'primary',
          others: 'success',
          pop: 'info',
          variable: 'variable',
        };

        const heightMax = 90;
        const colorFallbacks = ['primary', 'success', 'info'];

        $scope.markStyle = [];
        $scope.legendStyle = [];

        $scope.marks.forEach((s, i) => {
          const color = $scope.markTypes && typeColorMap[$scope.markTypes[i]] || colorFallbacks[i] || 'info';
          const markStyle = {
            left: `${s / $scope.maxScore * 100}%`,
            'z-index': i,
          };
          const legendStyle = {};
          let colorCss = `var(--${color})`;

          if ('variable' === color) {
            colorCss = `var(--scorebar-mark-start)`;
            markStyle.filter = `hue-rotate(${i * 360 / $scope.marks.length}deg)`;
            markStyle.height = ($scope.marks.length - i - 1) * heightMax / ($scope.marks.length - 1);
            legendStyle.filter = `hue-rotate(${i * 360 / $scope.marks.length}deg)`;
          } else {
            markStyle.height = [heightMax, 55, 20][i] || 0;
          }

          legendStyle['background-color'] = colorCss;
          markStyle['border-left-color'] = colorCss;
          markStyle.score = {
            'background-color': colorCss,
          };

          $scope.markStyle.push(markStyle);
          $scope.legendStyle.push(legendStyle);
        });

        const startRange = $scope.startRange / $scope.maxScore;
        const endRange = $scope.endRange / $scope.maxScore;
        $scope.rangeStyle = {
          left: startRange * 100 + '%',
          width: (endRange - startRange) * 100 + '%',
        };

        $scope.ticks = [...Array($scope.maxScore + 1).keys()];
      },
    };
  },
]);
