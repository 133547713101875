angular.module('hcap-analytics').controller('DataCtrl', [
  '$scope',
  '$http',
  '$state',
  'UserService',
  'AlertService',
  'Util',
  'DevPlanService',
function(
  $scope,
  $http,
  $state,
  UserService,
  AlertService,
  Util,
  DevPlanService,
) {
  $scope.data = {
    anonymize: true,
    includeRaters: true,
    consentingOnly: true,
    groupId: _.get($scope.dataContext, 'groupId') || null,
  };

  $scope.getDownloadUrl = function() {
    return '/api/user/download?' + _.reduce($scope.data, (acc, value, key) => {
      if (value) {
        acc.push(key + '=' + value);
      }
      return acc;
    }, []).join('&');
  };

  $scope.getDownloadTeamAverage = function() {
    return '/api/user/downloadTeamsAverage?' + _.reduce($scope.data, (acc, value, key) => {
      if (value) {
        acc.push(key + '=' + value);
      }
      return acc;
    }, []).join('&');
  };

  $scope.getDownloadTeamsIndividuals = function() {
    return '/api/user/downloadTeamsIndividuals?' + _.reduce($scope.data, (acc, value, key) => {
      if (value) {
        acc.push(key + '=' + value);
      }
      return acc;
    }, []).join('&');
  };

  $scope.downloadTeamsUnaggregated = function() {
    return '/api/user/downloadTeamsUnaggregated?' + _.reduce($scope.data, (acc, value, key) => {
      if (value) {
        acc.push(key + '=' + value);
      }
      return acc;
    }, []).join('&');
  };

  $scope.downloadTeamsUnaggregatedHexaco = function() {
    return '/api/user/downloadTeamsUnaggregatedHexaco?' + _.reduce($scope.data, (acc, value, key) => {
      if (value) {
        acc.push(key + '=' + value);
      }
      return acc;
    }, []).join('&');
  };
/*
  $scope.readPerms = workbook => {
    const contents = Util.parseSheet(workbook);
    const users = [];

    for (const sheetName in contents) {
      const sheetData = contents[sheetName];

      sheetData.forEach(row => {
        users.push(Object.keys(row).reduce((acc, key) => {
          if (key === 'email') {
            return { ...acc, email: row[key] };
          } else if (row[key] === '1') {
            acc[key] = true;
            return acc;
          }

          return acc;
        }, {}));
      });
    }

    if (users.length === 0) {
      AlertService.warning('No users found in the file.', 'Error');
      return;
    }

    UserService.updatePerms(users).then(() => {
      $scope.data.permUploadSuccess = true;
    }).catch(() => {
      $scope.data.permUploadSuccess = false;
    });
  };
*/

  $scope.readSuggests = workbook => {
    const contents = Util.parseSheet(workbook);
    const suggests = contents['Sheet1'];

    if (suggests.length === 0) {
      AlertService.warning('No suggests found in the file.', 'Error');
      return;
    }

    DevPlanService.updateSuggests(suggests).then(() => {
      $scope.data.suggestUploadSuccess = true;
    }).catch(() => {
      $scope.data.suggestUploadSuccess = false;
    });
  };
}]);
