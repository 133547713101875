angular.module('hcap-analytics').controller('LandingConsentCtrl', [
  '$scope',
  'UserService',
  'StateService',
  'AlertService',
  '$http',
  '$state',
  '$location',
  '$window',
function(
  $scope,
  UserService,
  StateService,
  AlertService,
  $http,
  $state,
  $location,
  $window,
) {
  $scope.data = {
    profile: {},
    consentText: null,
    errorMessage: null,
    busy: false,
    years: [],
  };

  let currentYear = moment().year();
  for(let i=0; i < 100; i++) {
    $scope.data.years.push(currentYear);
    currentYear--;
  }

  UserService.getConsentText((text) => {
    $scope.data.consentText = text;
  });

  $scope.onEmailChange = function() {
    if (!$scope.data.profile.email) {
      return;
    }

    UserService.validateEmail($scope.data.profile.email).then(response => {
      if (!response.exists) {
        $scope.data.busy = false;
        $scope.data.errorMessage = null;
      } else {
        $scope.data.busy = true;
        $scope.data.errorMessage = response.message;
      }
    });
  };

  $scope.onContinue = function() {
    $scope.data.busy = true;
    $scope.data.errorMessage = null;

    UserService.signUp({
      name: $scope.data.profile.name,
      email: $scope.data.profile.email,
      src: localStorage.getItem('src'),
    }, function(user, errorMessage) {
      if (!user) {
        $scope.data.busy = false;
        $scope.data.errorMessage = errorMessage;
      } else {
        UserService.saveProfile($scope.data.profile, function(user, errorMessage) {
          $scope.data.busy = false;
          if (!user) {
            $scope.data.errorMessage = errorMessage;
          } else {
            StateService.land();
          }
        });
      }
    }, true);
  };
}]);
