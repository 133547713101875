angular.module('hcap-analytics')
.controller('UsersCtrl', ['$scope','$http','$state','UserService','AlertService', function($scope,$http,$state,UserService,AlertService) {

	$scope.users=[]
	$scope.totalCount=0
	$scope.form={}
	$scope.editMode=false
	$scope.query = "";

	$scope.pose = function(guid) {
		UserService.pose(guid)
	}

	$scope.viewDetails = function(guid) {
		window.location.href = "v1/userDetails/" + guid;
	}

	$scope.getUsers=function(){
		UserService.getAll(function(response){
			$scope.users=response.users
			$scope.totalCount=response.totalCount
		})
	}

	$scope.search = function() {
		if(!$scope.query) {
			$scope.getUsers();
			return;
		}

		UserService.search($scope.query, function(response){
			$scope.users=response.users
			$scope.totalCount=response.totalCount
		})
	}

	$scope.resetForm=function(){
		$scope.form={
			name:"",
			email:""
		}
		$scope.editMode=false
	}

	$scope.editUser=function(entry){
		$scope.form={
			name: entry.name,
			email: entry.email,
			_id: entry._id
		}
		$scope.editMode=true
	}

	$scope.create = function(newUserForm){
		if(newUserForm.$invalid) {
			return
		}

		$http.post('./api/user',$scope.form).then(function(response){
			if(response.data.success) {
				if (!$scope.editMode) {
					AlertService.alert('An email invitation has been sent to '+$scope.form.email+'.',"Account Created")
				}
				$scope.resetForm()
				$scope.getUsers()
			} else {
				AlertService.warning('Duplicate email: '+$scope.form.email,'Error')
			}
		},function(error){
			console.log(error.data)
		})
	}

	$scope.activate = function(email){
		$http.post('./api/user/'+email.toLowerCase()+'/activate')
		.then(function(response){
			$scope.getUsers();
		})
		.catch(function(error){

		})
	}

	$scope.deactivate = function(email){
		$http.post('./api/user/'+email.toLowerCase()+'/deactivate')
		.then(function(response){
			$scope.getUsers();
		})
		.catch(function(error){

		})
	}

	$scope.resetPassword = function(email){
		$http.post('./api/user/'+email.toLowerCase()+'/reset').then(function(response){
			AlertService.alert('An email notification containing a Password Reset Link has been sent to '+email.toLowerCase()+'.')
		},function(error){
			console.log(error)
		})
	}

	$scope.isAdmin=function(){
		return UserService.getUser().role=="Administrator"
	}

	$scope.isNotBuiltInAdmin = function(entry){
		return entry.email!="support@hcap-analytics.com"
	}

	$scope.resetForm()
	$scope.getUsers()
}])
