angular.module('hcap-analytics')
.controller('LandingCtrl', [
  '$scope',
  'UserService',
  '$state',
  function(
    $scope,
    UserService,
    $state,
) {
  $scope.data = {
    mode: 'SIGN_IN',
  };

  $scope.navTab = mode => {
    $scope.data.mode = mode;
  };
}]);
