angular.module('hcap-analytics')
.controller('AssessmentDashboardCtrl', ['$scope','$stateParams','AssessmentService','AlertService','ParticipantService','GroupService','UserService', function($scope,$stateParams,AssessmentService,AlertService,ParticipantService,GroupService,UserService) {

	$scope.error = null
	$scope.guid = $stateParams.guid
	$scope.participantCount = null;
	$scope.completedParticipantCount = null;
	$scope.consentParticipantCount = null;
	$scope.groupInfo = {
		groups: [],
		selectedGroupGuid: null
	};

    AssessmentService.get($scope.guid,function(response){
		$scope.assessment=response.assessment
		updateCounts();
		if(response.success==false) {
			$scope.error=true
		}
	})

	function updateCounts() {
		var emails = [];
		$scope.participantCount = $scope.assessment.participants.length;
		if ($scope.groupInfo.selectedGroupGuid) {
			$scope.participantCount = 0;
		}
		$scope.completedParticipantCount = 0;
		$scope.assessment.participants.forEach(function(p) {
			p.email = p.email.toLowerCase()
			if ($scope.groupInfo.selectedGroupGuid) {
				if (!p.filter) {
					emails.push(p.email);
					$scope.participantCount++;
					if (p.completed) {
						$scope.completedParticipantCount++;
					}
				}
			} else {
				emails.push(p.email);
				if (p.completed) {
					$scope.completedParticipantCount++;
				}
			}
		});

		UserService.getConsentStatus(emails,function(response) {
			var consentStatus = { };
			response.forEach(function(entry) {
				entry.email = entry.email.toLowerCase();
				consentStatus[entry.email] = entry.consent;
			})
			$scope.consentParticipantCount = 0;
			$scope.assessment.participants.forEach(function(p) {
				p.email = p.email.toLowerCase();
				p.consent = consentStatus[p.email];
				if (p.consent=='Yes') {
					$scope.consentParticipantCount++
				}
			})
		})
	}

	GroupService.getAll(function (groups) {
		$scope.groupInfo.groups = groups;
	})

	$scope.onGroupChange = function() {
		if ($scope.groupInfo.selectedGroupGuid) {
			GroupService.get($scope.groupInfo.selectedGroupGuid, function(group) {
				$scope.groupInfo.group = group;
				var emails = _.map($scope.groupInfo.group.list, 'email');
				$scope.assessment.participants.forEach(function (participant){
					if (emails.indexOf(participant.email) == -1) {
						participant.filter = true;
					} else {
						delete participant.filter;
					}
				})
				updateCounts();
			})
		} else {
			$scope.assessment.participants.forEach(function (participant){
				delete participant.filter;
			})
			updateCounts();
		}
	}

	$scope.resendAssessmentLink=function(participant) {
		AlertService.confirm("Are you sure you want to resend the assessment link to the participant?","Resend Assessment Link",function(choice){
			if(choice) {
				ParticipantService.sendNotification(participant.guid,function(ret){
					if(ret.success) {

					} else {
						AlertService.warning("Unable to resend notification",ret.message)
					}
				})
			}
		})
	}

	$scope.removeParticipant=function(participant) {
		AlertService.confirm("Are you sure you want to remove this participant?","Participant Removal",function(choice){
			if(choice) {
				AssessmentService.removeParticipant($scope.guid,participant.guid,function(ret){
					if(ret.success) {
						$scope.assessment=ret.assessment
					} else {
						AlertService.warning("Unable to remove participant",ret.message)
					}
				})
			}
		})

	}

	$scope.reopenParticipant=function(participant) {
		AlertService.confirm("Are you sure you want to reopen the assessment this participant?","Reopen Assessment",function(choice){
			if(choice) {
				AssessmentService.reopenParticipant($scope.guid,participant.guid,function(ret){
					if(ret.success) {
						$scope.assessment=ret.assessment
					} else {
						AlertService.warning("Unable to reopen assessment for participant",ret.message)
					}
				})
			}
		})
	}

}])
