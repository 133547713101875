angular.module('hcap-analytics')
.controller('TeamNextCtrl', ['$scope', 'CohortsService', function($scope, CohortsService) {
  $scope.loaded = false;
  $scope.hasTeams = false;
  $scope.basicEnabled = true;

  CohortsService.summary().then(summary => {
    $scope.hasTeams = summary.length > 0;

    for (cohort of summary) {
      for (phase of cohort.phases) {
        if (+new Date(phase.dueDate) > +new Date()) {
          $scope.basicEnabled = false;
          return;
        }
      }
    }
  }).finally(() => {
    $scope.loaded = true;
  });

}]);
