angular.module('hcap-analytics')
  .controller('UserCtrl', ['$scope', '$stateParams', '$http', '$state', 'UserService', 'AssessmentService', 'localStorageService', 'TemplateService', '$rootScope', function($scope, $stateParams, $http, $state, UserService, AssessmentService, localStorageService, TemplateService, $rootScope) {

    $scope.taggedAssessments = [];
    $scope.assessments = [];
    $scope.templates = [];
    $scope.groupedAssessments = {};
    $scope.user = {};
    $scope.templateCategoryCaptions = _.clone($rootScope.templateCategoryCaptions);

    const assessOrder = {
      '92Ob1BX3Gj5fxXeOCb1J7V': 0,
      'QrOG1AqdFHyh7_fZb0_DEk': 1,
      'qbmT7jOtHGibfCNEAFPmkF': 2,
      'MvMfjZa_G1TfNkMxkUednF': 3,
      'TCEiSnwpGLanJWr_p81SR': 4,

      'tsFT6dfyF.aG0nMpUzeP3V': 0,
      'lcIVxYLqFWXwcItJ1KDx4V': 1,
      '3CY5ZmYyGLie3PYTs7xLhV': 2,
      '8KSW.dmFHoimfn.CZni7Pk': 3,
      '.DpUp9A0Gimj.Djr49L_3F': 4,
    };

    AssessmentService.getAllUserAssessments().then(function(assessments) {
      const sorted = _.sortBy(assessments, function(a) {
        return assessOrder[a.guid] !== undefined ? assessOrder[a.guid].toString() : a.name;
      });

      sorted.forEach(function(assessment) {
        if (assessment.participant.designatedRater) {
          if (!assessment.participant.completed) {
            $scope.taggedAssessments.push(assessment);
          }
        } else {
          $scope.assessments.push(assessment);
        }
      });
      $scope.user = UserService.getUser();
    });

  }]);
