angular.module('hcap-analytics')
.controller('TemplateEditCtrl', ['$scope','$http','$state','$stateParams','AlertService','TemplateService','$rootScope', 'Util', function($scope,$http,$state,$stateParams,AlertService,TemplateService,$rootScope, Util) {

	$scope.guid = $stateParams.guid;
	$scope.template = null;
	$scope.sortableOptions = {
		handle: '> td > .item-drag-handle'
	};

	$scope.newItem = { key:null, prompt:null, type:null };

	$scope.types = [
		{ value: null, caption:"-- Select Response --" },
		{ value:"caption", caption:"Caption" },
		{ value:"caption_minor", caption:"Minor Caption" },
		{ value:"likert_agreement_5", caption:"Likert - Agreement (5)", reverse_option:true },
		{ value:"likert_agreement_5_alt", caption:"Likert - Agreement (0-5)", reverse_option:true },
		{ value:"likert_agreement_7", caption:"Likert - Agreement (7)", reverse_option:true },
		{ value:"likert_chances_5", caption:"Likert - Chances (5)", reverse_option:true },
		{ value:"likert_characteristic_5", caption:"Likert - Characteristic (5)", reverse_option:true },
		{ value:"likert_effectiveness_5", caption:"Likert - Effectiveness (5)", reverse_option:true },
		{ value:"likert_fair_7", caption:"Likert - Fairness (7)", reverse_option:true },
		{ value:"likert_frequency_5", caption:"Likert - Frequency (5)" , reverse_option:true },
		{ value:"likert_frequency_5_b", caption:"Likert - Frequency B (5)" , reverse_option:true },
		{ value:"likert_frequency_5_c", caption:"Likert - Frequency C (5)" , reverse_option:true },
		{ value:"likert_importance_5", caption:"Likert - Importance (5)", reverse_option:true },
		{ value:"likert_just_7", caption:"Likert - Just (7)", reverse_option:true },
		{ value:"likert_likelyhood_5", caption:"Likert - Likelyhood (5)", reverse_option:true },
		{ value:"likert_likely_5", caption:"Likert - Likeliness (5)", reverse_option:true },
		{ value:"likert_likely_7", caption:"Likert - Likeliness (7)", reverse_option:true },
		{ value:"likert_moral_7", caption:"Likert - Moral (7)", reverse_option:true },
		{ value:"likert_recognition_5", caption:"Likert - Recognition", reverse_option:true },
		{ value:"likert_relevance_5", caption:"Likert - Relevance (0-5)", reverse_option:true },
		{ value:"likert_understanding", caption:"Likert - Understanding", reverse_option:true },
		{ value:"round_robin_all", caption:"Round Robin - All" },
		{ value:"round_robin_all_numbers", caption:"Round Robin - All (Numbers Only)" },
		{ value:"round_robin_others", caption:"Round Robin - Others" },
		{ value:"round_robin_others_numbers", caption:"Round Robin - Others (Numbers Only)" },
		{ value:"user_select", caption:"User Select" },
		{ value:"page_break", caption:"Page Break" },
		{ value:"true_false", caption:"True or False" },
		{ value:"written_response_line", caption:"Written Response (1 Line)" },
		{ value:"written_response_lines", caption:"Written Response (Multiple Lines)" },
		{ value:"yes_no", caption:"Yes or No" },
		{ value:"rater_type", caption:"Rater Type" },
		{ value:"team_effective_header", caption:"Team Effectiveness Header" },
		{ value:"team_table_contrib", caption:"Team Table Contrib" },
		{ value:"team_table_interact", caption:"Team Table Interact" },
		{ value:"team_table_track", caption:"Team Table Track" },
		{ value:"team_table_quality", caption:"Team Table Quality" },
		{ value:"team_table_knowledge", caption:"Team Table Knowledge" },

		{ value:"checkbox", caption:"Checkbox" },
	]

	$scope.load = function() {
		TemplateService.get($scope.guid,function(template){
			$scope.template = template;

			if(!$scope.template.questions || $scope.template.questions.length == 0) {
				$scope.resetTemplateQuestions();
			}
			if(!$scope.template.designatedRaterQuestions || $scope.template.designatedRaterQuestions.length == 0) {
				$scope.resetTemplateDesignatedRaterQuestions();
			}

			$scope.$watch('template',function(newVal,oldVal,scope){
				$scope.normalize();
				TemplateService.save($scope.guid,$scope.template,function(success){
					if(!success) {
						AlertService.alert('Your changes were not saved. Please sign out and then sign in before making any more changes.')
					} else {
						console.log('saved template')
					}
				})
			},true)

		})
	}

	$scope.normalize = function() {
		var keys = $scope.template.questions.map(function(question) {
			return question.key;
		})

		keys = _.compact(keys);
		var duplicates = [];
		var occurrences = {};
		keys.forEach(function(key) {
			occurrences[key] = occurrences[key] || 0;
			occurrences[key]++;
			if(occurrences[key] > 1) {
				duplicates.push(key)
			}
		})

		$scope.template.questions.forEach(function(question) {
			if(_.includes(duplicates,question.key)) {
				question.duplicate = true;
			} else {
				delete question.duplicate;
			}
		})

		var displayIndex = 1;
		$scope.template.questions.forEach(function(question) {
			if(!$scope.canIgnore(question)) {
				question.displayIndex = displayIndex;
				displayIndex++;
			} else {
				question.displayIndex = '';
			}
		})

		displayIndex = 1;
		$scope.template.designatedRaterQuestions.forEach(function(question) {
			if(!$scope.canIgnore(question)) {
				question.displayIndex = displayIndex;
				displayIndex++;
			} else {
				question.displayIndex = '';
			}
		})

	}

	$scope.deleteItem = function(index, designatedRater){
		if(designatedRater) {
			var item = $scope.template.designatedRaterQuestions[index];
			AlertService.confirm('This will delete item: "'+(item.prompt || 'Empty')+'"', null, function(choice) {
				if(choice) {
					$scope.template.designatedRaterQuestions.splice(index,1);
				}
			})
		} else {
			var item = $scope.template.questions[index];
			AlertService.confirm('This will delete item: "'+(item.prompt || 'Empty')+'"', null, function(choice) {
				if(choice) {
					$scope.template.questions.splice(index,1);
				}
			})
		}
	}

	$scope.showDesignatedRaterPreview = function() {
		if(!$scope.template || !$scope.template.designatedRaterQuestions) {
			return false;
		}
		return $scope.template.designatedRaterQuestions.length>1;
	}

	$scope.addItem = function(index, designatedRater){
		if(designatedRater) {
			index = index || $scope.template.designatedRaterQuestions.length;
			$scope.template.designatedRaterQuestions.splice(index,0,_.clone($scope.newItem));
		} else {
			index = index || $scope.template.questions.length;
			$scope.template.questions.splice(index,0,_.clone($scope.newItem));
		}
	}

	$scope.itemHasReverseOption = function(question) {
		for(var i=0;i<$scope.types.length;i++) {
			if(question.type==$scope.types[i].value && $scope.types[i].reverse_option) {
				return true;
			}
		}
		return false;
	}

	$scope.deleteAllItems = function() {
		AlertService.confirm('Are you sure you want to delete all the items in the template?',null,function(response){
			if(response) {
				$scope.resetTemplateQuestions();
				$scope.resetTemplateDesignatedRaterQuestions();
			}
		})
	}

	$scope.resetTemplateQuestions = function() {
		$scope.template.questions = [ _.clone($scope.newItem) ];
	}

	$scope.resetTemplateDesignatedRaterQuestions = function() {
		$scope.template.designatedRaterQuestions = [ _.clone($scope.newItem) ];
	}

	$scope.read = function (workbook) {

		var validQuestionTypes = $scope.types.map(function(type) {
			return type.value;
		})

		var truthinessConversion = {
			"": false,
			"yes": true,
			"true": true,
			"no": false,
			"false": false
		}

		var contents=to_json(workbook)
		var contentsList = _.values(contents);
		for(var i=0; i < contentsList.length && i <2 ; i++) {
			var sheet = contentsList[i];
			if(sheet.length > 0 && $scope.template.questions.length==1 && !$scope.template.questions[0].key && !$scope.template.questions[0].prompt && !$scope.template.questions[0].type) {
				$scope.template.questions = [];
			}
			if(sheet.length > 0 && $scope.template.designatedRaterQuestions.length==1 && !$scope.template.designatedRaterQuestions[0].key && !$scope.template.designatedRaterQuestions[0].prompt && !$scope.template.designatedRaterQuestions[0].type) {
				$scope.template.designatedRaterQuestions = [];
			}

			sheet.forEach(function(row) {
				for(var key in row) {
					row[key.toLowerCase()] = row[key];
				}

				var reverse_scored_val = row.reverse_scored || "";
				reverse_scored_val = reverse_scored_val.toLowerCase();
				if(truthinessConversion.hasOwnProperty(reverse_scored_val)) {
					reverse_scored_val = truthinessConversion[reverse_scored_val];
				}

				var newQuestion = {
					key: row.key,
					prompt: row.prompt,
					type: row.type ? row.type.toLowerCase() : "",
					reverse_scored: reverse_scored_val
				}
				if(validQuestionTypes.indexOf(newQuestion.type)==-1) {
					delete newQuestion.type;
				}
				if (i==0) {
					$scope.template.questions.push(newQuestion);
				} else {
					$scope.template.designatedRaterQuestions.push(newQuestion);
				}
			})
		}

		$scope.$apply();
	}

	$scope.error = function (e) {
		console.log(e)
		AlertService.warning("Unable read file","Error")
	}

  $scope.hidePromptBox = Util.hidePromptBox;
  $scope.hideKeyBox  = Util.hideKeyBox;
  $scope.canIgnore  = Util.canIgnore;

	function to_json(workbook) {
		var result = {}
		workbook.SheetNames.forEach(function(sheetName) {
			var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
			if(roa.length > 0){
				result[sheetName] = roa
			}
		})
		return result
	}

	$scope.load();
}])
