angular.module('hcap-analytics').service('DevPlanService', ['$http', '$q', function($http, $q) {

  // needs to be synced in api call
  const suggestSubscaleMap = [{
    scale: 'WO_HUMLE',
    subscales: [
      'WO_HUMLE_SIN',
      'WO_HUMLE_FAR',
      'WO_HUMLE_GA',
      'WO_HUMLE_MOD',
    ],
  }, {
    scale: 'WO_EMOLE',
    subscales: [
      'WO_EMOLE_FRF',
      'WO_EMOLE_ANX',
      'WO_EMOLE_DEP',
      'WO_EMOLE_SEN',
    ],
  }, {
    scale: 'WO_EXTRLE',
    subscales: [
      'WO_EXTRLE_SSE',
      'WO_EXTRLE_BLD',
      'WO_EXTRLE_SOC',
      'WO_EXTRLE_LIV',
    ],
  }, {
    scale: 'WO_AGREE',
    subscales: [
      'WO_AGREE_FOG',
      'WO_AGREE_GEN',
      'WO_AGREE_FEX',
      'WO_AGREE_PAT',
    ],
  }, {
    scale: 'WO_CONSCI',
    subscales: [
      'WO_CONSCI_ORG',
      'WO_CONSCI_DIL',
      'WO_CONSCI_PER',
      'WO_CONSCI_PRU',
    ],
  }, {
    scale: 'WO_OPENE',
    subscales: [
      'WO_OPENE_AA',
      'WO_OPENE_INQ',
      'WO_OPENE_CRE',
      'WO_OPENE_UCN',
    ],
  }];

  const activityTemplate = {
    text: null,
    result: null,
    categoryId: null,
  };

  const goalTemplate = {
    dateStart: null,
    dateEnd: null,
    text: '',
    activities: [],
  };

  const cached = {
    plans: {},
    shares: null,
  };

  this.newGoal = (devPlan, data) => {
    devPlan.goals.unshift({
      ..._.cloneDeep(goalTemplate),
      ...data,
    });
  };

  this.newActivity = (goal, data) => {
    goal.activities.push(_.cloneDeep({
      ...activityTemplate,
      ...data,
    }));
  };

  this.deleteGoal = (devPlan, goal) => {
    devPlan.goals.splice(devPlan.goals.indexOf(goal), 1);
  };

  this.deleteActivity = (goal, index) => {
    goal.activities.splice(index, 1);
  };

  this.deleteResult = activity => {
    activity.result = null;
  };

  this.stats = devPlan => {
    return (devPlan && {
      started: devPlan.goals.length,
      completed: devPlan.goals.filter(g => g.complete).length,
    }) || {};
  };

  this.get = (userGuid = null, force) => {
    if (!force && cached.plans[userGuid]) {
      return $q.resolve(cached.plans[userGuid]);
    }

    let url = './api/devplan';
    if (userGuid) {
      url += '/' + userGuid;
    }

    return $http.get(url).then(response => {
      cached.plans[userGuid] = response.data;
      return response.data;
    });
  };

  this.update = devPlan =>  {
    return $http.post('./api/devplan', devPlan).then(response => {
      return response.data;
    });
  };

  this.updateSuggests = suggests => {
    return $http.post('./api/devplan/suggestions', suggests);
  };

  this.getSuggestsFromGoals = goals => {
    return this.getSuggests(goals.map(goal => goal.scaleId).filter(scaleId => scaleId));
  };

  this.getSuggests = ids => {
    if (!ids.length) {
      return $q.resolve([]);
    } else {
      return $http.get('./api/devplan/suggestions?ids=' + ids.join(',')).then(res => res.data).catch(() => []);
    }
  };

  this.suggestExists = (suggests, scaleId) => {
    const mapping = suggestSubscaleMap.find(s => s.subscales.includes(scaleId));
    return !!suggests.find(s => s.scaleId === (mapping ? mapping.scale : scaleId));
  };

  this.getSuggest = (suggests, scaleId) => {
    const mapping = suggestSubscaleMap.find(s => s.subscales.includes(scaleId));
    return suggests.filter(s => s.scaleId === (mapping ? mapping.scale : scaleId));
  };

  this.getSuggestId = (suggests, suggestId) => {
    return suggests.find(s => s.id === suggestId);
  };

  this.getShares = force => {
    if (!force && cached.shares) {
      return $q.resolve(cached.shares);
    }

    return $http.get('./api/devplan/shares').then(res => {
      cached.shares = res.data;
      return cached.shares;
    });
  };

  this.share = (data) => {
    return $http.post('./api/devplan/shares', data);
  };

  this.shareDelete = guid => {
    return $http.delete('./api/devplan/shares/' + guid);
  };
}]);
