angular.module('hcap-analytics').service('InvitesService', [
  '$http',
  '$state',
  '$q',
  'AssessmentService',
  'CohortsService',
  'UserService',
  'Util',
function(
  $http,
  $state,
  $q,
  AssessmentService,
  CohortsService,
  UserService,
  Util,
) {
  this.noticeCount = 0;
  this.taggedAssessments = [];
  this.unfinishedPhases = [];

  this.update = () => {
    $q.all([
      AssessmentService.getUserDesignatedAssessments(),
      CohortsService.summary(),
    ]).then(res => {
      const userId = UserService.getUser()._id;
      this.taggedAssessments = res[0];

      this.unfinishedPhases = res[1].reduce((acc, cohort) => {
        const completion = cohort.team.members.find(m => m.id === userId).completion;

        for (let i = 0, len = cohort.phases.length; i < len; i++) {
          const phase = cohort.phases[i];
          const open = +phase.startDate <= +new Date() && +phase.dueDate >= +new Date();

          if (open &&
            (('setup' === phase.type && (!completion[i].charter || !completion[i].skills)) || !completion[i])
          ) {
            acc.push({
              cohortId: cohort._id,
              cohortName: cohort.name,
              phaseName: Util.phaseDisplay(i, cohort.phases),
              phaseIndex: i,
              dueDate: phase.dueDate,
            });
          }
        }

        return acc;
      }, []);

      this.noticeCount = this.taggedAssessments.length + this.unfinishedPhases.length;
    });
  };
}]);
