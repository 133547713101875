angular.module('hcap-analytics')
.controller('CohortCtrl', [
  '$location',
  '$q',
  '$scope',
  '$state',
  '$transitions',
  'CohortsService',
  '$uibModal',
  'AlertService',
  'Util',
  function(
    $location,
    $q,
    $scope,
    $state,
    $transitions,
    CohortsService,
    $uibModal,
    AlertService,
    Util,
) {
  const cohortId = $state.params.id;

  $scope.data = {
    cohort: null,
    users: [],
    teams: [],
    pickers: [],
    pickerEdit: [],
    tabSelect: $location.search().results ? 'results' : 'parts',
    tabPhaseSelect: 0,
    bulkUploadSuccess: null,
    errors: {
      phaseDate: [],
      phaseOrder: {
        index: -1,
        correct: -1,
        name: null,
      },
    },
    teamSelected: null,
    teamCompletion: null,
    introHide: true,
    skillsDisplay: null,
  };

  const setup = (force) => {
    return $q.all([
      CohortsService.getId(cohortId, force),
      CohortsService.getUsers(cohortId),
    ]).then(([cohort, users]) => {
      const modUsers = [];
      cohort.teams.forEach((t, i) => {
        modUsers.push.apply(modUsers, t.members.map(mId => ({
          ...users.find(u => u._id === mId),
          team: i + 1,
        })));
      });

      $scope.data.cohort = cohort;
      $scope.data.users = modUsers;
      $scope.data.unassignedUsers = users.filter(u => !cohort.teams.find(t => t.members.find(m => m === u._id)));

      $scope.data.teams = _.cloneDeep(cohort.teams).map(t => {
        t.members = t.members.map(m => users.find(u => u._id === m));
        return t;
      });

      $scope.validatePhases();
    });
  };

  setup();

  $scope.phaseSortable = phase => {
    return !['setup', 'prep'].includes(phase.type);
  };

  $scope.toggleIntro = () => {
    $scope.data.introHide = !$scope.data.introHide;
  };

  $scope.pickerOptions = {
    showWeeks: false,
  };

  $scope.allTeamData = [];

  $scope.loadData = teams => {
    $scope.allTeamData = [];

    teams.forEach(t => {
      const data = {
        index: $scope.data.teams.findIndex(te => te.id === t.id),
        team: t,
        phases: [],
      };

      $scope.data.cohort.phases.forEach((p, i) => {
        const c = {
          displaySkills: false,
          data: {},
          participateData: {
            templateId: null,
            participantId: null,
            members: null,
            orgView: true,
          },
        };

        $scope.loadTeamData(t.id, p, c);
        data.phases.push(c);
      });

      $scope.allTeamData.push(data);
    });
  };

  $scope.sortOptions = {
    handle: '> td > .drag-handle',
    stop: (event, ui) => {
      CohortsService.updateCohort($scope.data.cohort).then(() => $scope.onUpdatePhase()).then(setup);
    },
    update: function(e, ui) {
      if (ui.item.sortable.dropindex < $scope.data.cohort.phases.filter(p => ['setup', 'prep'].includes(p.type)).length) {
        ui.item.sortable.cancel();
      }
    },
  };

  const numProgressReports = () => {
    return _.get($scope, 'data.cohort.phases', []).filter(p => p.type === 'prog').length;
  };

  const numEvalReports = () => {
    return _.get($scope, 'data.cohort.phases', []).filter(p => p.type === 'eval').length;
  };

  $scope.newTeam = () => {
    CohortsService.addTeam($scope.data.cohort._id).then(() => setup(true));
  };

  $scope.deleteTeam = tIndex => {
    $scope.data.cohort.teams.splice(tIndex, 1);
    CohortsService.updateCohort($scope.data.cohort).then(setup);
  };

  $scope.addTeamMember = tIndex => {
    const teams = $scope.data.cohort.teams;
    const users = () => $scope.data.unassignedUsers;
    const cohort = $scope.data.cohort;

    $uibModal.open({
      templateUrl: 'templates/partials/_addMember.html',
      controller: ['$uibModalInstance', '$scope', ($uibModalInstance, $scope) => {
        $scope.data = {
          users: users(),
          selected: null,
        };

        $scope.addMember = () => {
          teams[tIndex].members.push($scope.data.selected);
          $scope.data.selected = null;
          CohortsService.updateCohort(cohort).then(setup).then(() => {
            $scope.data.users = users();
          });
        };

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };
      }],
    });
  };

  $scope.makeLeader = (tIndex, leaderId) => {
    const cohort = $scope.data.cohort;
    const teamId = cohort.teams[tIndex].id;
    CohortsService.setTeamLeader(cohort._id, teamId, leaderId).then(setup);
  };

  $scope.editPhase = pIndex => {
    const parentScope = $scope;

    $uibModal.open({
      templateUrl: 'templates/partials/_editPhase.html',
      controller: ['$uibModalInstance', '$scope', ($uibModalInstance, $scope) => {
        const cohort = parentScope.data.cohort;
        const phase = _.cloneDeep(cohort.phases[pIndex]);
        const disableDelete = phase.type === 'prog' && parentScope.minProgressReports() ||
          phase.type === 'eval' && parentScope.minEvalReports() ||
          (cohort.finalized && (+new Date()) > +phase.startDate);

        $scope.data = {
          name: parentScope.phaseDisplay(pIndex, cohort.phases),
          phase,
          disableDelete,
          //minDate: parentScope.minPhaseDate(cohort, pIndex),
        };

        $scope.save = () => {
          if ($scope.data.form.$valid) {
            $scope.data.form.$setSubmitted();
            cohort.phases[pIndex] = phase;
            CohortsService.updateCohort(cohort).then(setup).then(() => {
              $uibModalInstance.close();
              parentScope.onUpdatePhase();
            });
          } else {
            document.querySelectorAll('input').forEach(e => e.reportValidity());
          }
        };

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.delete = () => {
          cohort.phases.splice(pIndex, 1);
          CohortsService.deletePhase(cohort._id, pIndex).then(() => {
            $uibModalInstance.dismiss('cancel');
            parentScope.onUpdatePhase();
          }).then(setup);
        };
      }],
    });
  };

  $scope.onUpdatePhase = () => {
    $scope.validatePhases();
  };

  $scope.removeTeamMember = (tIndex, member) => {
    const team = $scope.data.cohort.teams[tIndex];
    const cohortMembers = team.members;
    cohortMembers.splice(cohortMembers.indexOf(member._id), 1);

    if (team.leaderId === member._id) {
      team.leaderId = null;
    }

    CohortsService.updateCohort($scope.data.cohort).then(setup);
  };

  $scope.read = workbook => {
    const contents = Util.parseSheet(workbook);
    const participants = [];
    const regex = /[^a-zA-Z0-9@_\-\.]/gi;

    for (const sheetName in contents) {
      const sheetData = contents[sheetName];

      sheetData.forEach(row => {
        const name = row[Object.keys(row).find(k => k.match(/name/i))];
        const email = row[Object.keys(row).find(k => k.match(/email/i))];
        const team = row[Object.keys(row).find(k => k.match(/team/i))];

        participants.push({
          name,
          email: email.toLowerCase().replace(regex, ''),
          team: team.toLowerCase().replace(regex, ''),
        });
      });
    }

    if (participants.length === 0) {
      AlertService.warning('No participants found in the file.', 'Error');
      return;
    }

    CohortsService.uploadBulk(cohortId, participants).then(setup).then(() => {
      $scope.data.bulkUploadSuccess = true;
    }).catch(() => {
      $scope.data.bulkUploadSuccess = false;
    });
  };

  $scope.error = () => {
    $scope.data.bulkUploadSuccess = false;
  };

  $scope.finalize = () => {
    const parentScope = $scope;

    $uibModal.open({
      templateUrl: 'templates/partials/_finalizeCohort.html',
      controller: ['$uibModalInstance', '$scope', ($uibModalInstance, $scope) => {
        $scope.ok = () => {
          $scope.loading = true;

          CohortsService.finalize(parentScope.data.cohort._id).then(() => {
            parentScope.data.cohort.finalized = true;
            setup();
            $uibModalInstance.close();
            AlertService.alert('Team setup successfully finalized', 'Team Setup Finalized');
          }).catch(() => {
            $uibModalInstance.close();
            AlertService.error('Error', 'Team was not finalized, please try again.', 'Ok');
          });
        };

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };
      }],
    });
  };

  $scope.inviteUser = () => {
    $uibModal.open({
      templateUrl: 'templates/partials/_inviteParticipant.html',
      controller: ['$uibModalInstance', '$scope', ($uibModalInstance, $scope) => {
        $scope.data = {
          name: '',
          email: '',
        };

        $scope.ok = () => {
          if ($scope.data.form.$valid) {
            CohortsService.uploadBulk(cohortId, [{
              ...$scope.data,
              team: '',
            }]).then(() => {
              setup();
              $uibModalInstance.close();
              AlertService.alert('User successfully invited', 'Invite Success');
            });
          } else {
            document.querySelectorAll('input').forEach(i => i.reportValidity());
          }
        };

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };
      }],
    });
  };

  $scope.uploadClassResults = () => {
  };

  $scope.togglePicker = index => {
    const pickers = $scope.data.pickers;
    pickers[index] = !pickers[index];
  };

  $scope.pickerEdit = (index, state) => {
    $scope.data.pickerEdit[index] = state;
  };

  $scope.addPhase = type => {
    CohortsService.addPhase($scope.data.cohort._id, type).then(setup);
  };

  $scope.minProgressReports = () => {
    return numProgressReports() <= 1;
  };

  $scope.maxProgressReports = () => {
    return numProgressReports() >= 12;
  };

  $scope.minEvalReports = () => {
    return numEvalReports() <= 0;
  };

  $scope.maxEvalReports = () => {
    return numEvalReports() >= 12;
  };

  $scope.phaseDisplay = Util.phaseDisplay;

  //$scope.phaseDisplay = (index, phases) => {
    //const type = phases[index].type;
    //if (type === 'setup') {
      //return 'charter | skills';
    //} else {
      //return Util.phaseDisplay(index, phases);
    //}
  //};

  $scope.validatePhases = () => {
    $scope.updatePhaseDateErrors();
    $scope.updatePhaseOrderError();
  };

  $scope.updatePhaseOrderError = () => {
    $scope.data.errors.phaseOrder.index = -1;
    const cohort = $scope.data.cohort;
    const setupPhaseExists = $scope.data.cohort.phases.find(p => p.type === 'setup');

    for (let i = 0; i < $scope.data.cohort.phases.length; i++) {
      const type = cohort.phases[i].type;
      if (type === 'setup' && i !== 0) {
        $scope.data.errors.phaseOrder = {
          index: i,
          correct: 0,
          name: Util.phaseDisplay(i, cohort.phases),
        };
        return;
      } else if (type === 'prep' && (setupPhaseExists ? i !== 1 : i !== 0)) {
        $scope.data.errors.phaseOrder = {
          index: i,
          correct: setupPhaseExists ? 1 : 0,
          name: Util.phaseDisplay(i, cohort.phases),
        };
        return;
      }
    }
  };

  $scope.updatePhaseDateErrors = () => {
    $scope.data.errors.phaseDate = [];
    const cohort = $scope.data.cohort;

    cohort.phases.forEach((p, i) => {
      if (i === 0) {
        $scope.data.errors.phaseDate[i] = false;
      } else {
        const phaseDate = +cohort.phases[i].startDate;
        for (let j = i - 1; j >= 0; --j ) {
          if (phaseDate < +cohort.phases[j].startDate) {
            $scope.data.errors.phaseDate[i] = true;
            return;
          }
        }

        $scope.data.errors.phaseDate[i] = false;
      }
    });
  };

  $scope.minPhaseDate = (cohort, index) => {
    if (index == 0) {
      return cohort.phases[index].startDate;
    }

    let biggestDate = cohort.phases[index].startDate;

    for (let i = index - 1; i >= 0; --i ) {
      const phaseDate = cohort.phases[i].startDate;
      if (+biggestDate < +phaseDate) {
        biggestDate = phaseDate;
      }
    }

    return biggestDate;
  };

  $scope.anyPhaseDateErrors = () => $scope.data.errors.phaseDate.find(e => !!e);

  $scope.memberList = members => members.map(m => m.name).join(', ');

  $scope.phaseExists = phase => $scope.data.cohort && !!$scope.data.cohort.phases.find(p => p.type === phase);

  $scope.onTabSelect = tab => {
    $scope.data.tabSelect = tab;
  };

  $scope.onTabPhaseSelect = tab => {
    $scope.data.tabPhaseSelect = tab;

    if ($scope.data.teamSelected !== null) {
      $scope.onTeamSelect($scope.data.teamSelected, $scope.data.cohort.phases[tab]);
    }
  };

  $scope.onTeamSelect = (tId, phase) => {
    if ('ALL_TEAMS' === tId) {
      $scope.loadData($scope.data.teams);
    } else {
      $scope.loadData([$scope.data.teams.find(t => t.id === tId)]);
    }
  };

  $scope.loadTeamData = (tId, phase, container) => {
    if (!container) {
      container = $scope;
    }

    container.partDataReady = false;
    container.participateData.templateId = null;
    container.participateData.participantId = null;
    container.data.teamCompletion = null;

    if (phase.assessmentId) {
      const assessmentId = phase.assessmentId;

      CohortsService.teamPartData(cohortId, tId).then(partData => {
        const completed = partData.filter(p => p.assessmentId === assessmentId && p.completed);

        if (completed.length >= 3) {
          container.participateData.templateId = completed[0].templateId;
          container.participateData.participantId = completed[0].guid;
          container.participateData.members = $scope.data.teams.find(t => t.id === tId).members;
        }

        container.partDataReady = true;
      });
    } else {
      container.partDataReady = true;
      container.data.teamCompletion = $scope.data.teams.find(t => t.id === tId).members;

      CohortsService.skills(cohortId, tId, true, false, true).then(skills => {
        if (skills.stats) {
          const display = [];

          skills.stats.procrast && display.push(skills.stats.procrast['PRCRST']);
          skills.stats.ei && display.push(skills.stats.ei['EMOI']);
          skills.stats.hexaco && display.push(...Object.keys(skills.stats.hexaco).map(key => skills.stats.hexaco[key]));

          container.data.skillsDisplay = display;
          container.data.displaySkills = true;
        }
      });
    }
  };

  $scope.removeUser = user => {
    AlertService.prompt(`Remove ${_.startCase(user.name)} completely from teams module?`, 'Remove User', 'Remove User', 'Cancel', choice => {
      if (choice) {
        CohortsService.removeUser($scope.data.cohort._id, user._id).then(setup);
      }
    });
  };

  $scope.toggleTeamLeaders = () => {
    CohortsService.toggleLeaders($scope.data.cohort._id, !$scope.data.cohort.teamLeaders).then(setup);
  };

  const leaveHook = $transitions.onStart({from: 'cohort'}, transition => {
    if ($scope.data.cohort && !$scope.data.cohort.finalized) {
      return new Promise((resolve, reject) => {
        AlertService.confirm2('Would you like to continue?', 'Team Not Finalized', 'Continue', 'Go Back', choice => {
          choice ? resolve() : reject();
        });
      });
    }
  });

  $scope.$on('$destroy', () => {
    leaveHook();
  });

  $scope.print = () => {
    window.print();
  };

}]);
