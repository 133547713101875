angular.module('hcap-analytics')
.controller('AssessmentCtrl', ['$scope','$stateParams','UserService', function($scope,$stateParams,UserService) {

    $scope.guid=$stateParams.guid
    $scope.tabData = [
        {
            heading: 'Setup',
            route:   'assessment.setup',
            params:  {
                guid: $scope.guid
            }
        },
        {
            heading: 'Dashboard',
            route:   'assessment.dashboard',
            params:  {
                guid: $scope.guid
            }
        }
    ]

}])
