angular.module('hcap-analytics')
.controller('TeamsCtrl', [
  '$scope',
  '$http',
  '$state',
  'CohortsService',
function(
  $scope,
  $http,
  $state,
  CohortsService,
) {
  $scope.data = {
    summary: null,
  };

  CohortsService.summary().then(summary => {
    summary.sort((a, b) => {
      return +new Date(a.created) > +new Date(b.created) ? -1 : 1;
    });
    $scope.data.summary = summary.filter(c => c.finalized);
  });

  $scope.select = cohort => {
    $state.go('team', {id: cohort._id});
  };
}]);
