angular.module('hcap-analytics').constant('sectionsConfig', [
  {
    id: 'moral',
    name: 'moral character',
    styleName: 'moral',
    assessments: [
      {
        name: 'personal and work values',
        id: 'tsFT6dfyF.aG0nMpUzeP3V',
        templateGuid: 'qLbzstNJG8LBbopbB3aPhV',
        desc: `
        <ul>
          <li>What values are most important to you in your personal and work lives?</li>
          <li>This assessment explores 10 universal human values found to be important in 64 countries.</li>
          <li>It also explores 12 values that people prioritize to achieve meaning and engagement at work.</li>
        </ul>
        `,
      },
      {
        name: 'moral foundations',
        id: '8KSW.dmFHoimfn.CZni7Pk',
        templateGuid: 'lijUM_7CI39Y5m4RcGUs8k',
        desc: `
        <ul>
          <li>Assesses your standing on five psychological foundations on which people base their reasoning about moral issues.</li>
          <li>Moral foundations have important implications for moral, political, and ideological reasoning.</li>
        </ul>
        `,
      },
      {
        name: 'values-based decision making',
        id: 'lcIVxYLqFWXwcItJ1KDx4V',
        templateGuid: 'qctPnKGCHh94TBpBBDv_XF',
        desc: `<ul><li>Assesses your orientation towards situations and decision making that draw on your personal values.</li></ul>`,
      },
      {
        name: 'cynicism and egalitarianism',
        id: 'TCEiSnwpGLanJWr_p81SR.',
        templateGuid: "0KH_vEVqHgXl316CWclOck",
        desc: `<ul><li>Assesses your preference for and acceptance of hierarchy versus equality in social and political systems, as well as your outlook on people in general.</li></ul>`,
      },
      {
        name: 'values-based reasoning',
        id: '3CY5ZmYyGLie3PYTs7xLhV',
        templateGuid: "6zsQ7kSNGBT1TcbukSUXAk",
        desc: `
          <ul>
            <li>
              Assesses your standing on four personal attributes that affect how you attend to, reason, and derive motivation to deal with sensitive situations:
              <ul>
                <li>Attentiveness</li>
                <li>Identity</li>
                <li>Potency</li>
                <li>Disengagement</li>
              </ul>
            </li>
          </ul>
        `,
      },
    ],
  },
  {
    id: 'self',
    name: 'self leadership',
    styleName: 'self',
    assessments: [
      {
        name: 'HEXACO personality profile',
        section: 'hexaco',
        desc: `
        <ul>
          <li>Leadership begins with self awareness!</li>
          <li>
            Discover your standing on the “Big 6” personality dimensions, and a further breakdown into 24 specific traits.
            <ul>
              <li><b>H</b>onesty-Humility, <b>E</b>motionality, e<b>X</b>traversion, <b>A</b>greeableness, <b>C</b>onscientiousness, and <b>O</b>penness.</li>
            </ul>
          </li>
        </ul>
        * Bonus feature: You can invite others to rate you on this assessment
        `,
      },
      {
        name: 'emotional intelligence',
        id: 'QrOG1AqdFHyh7_fZb0_DEk',
        templateGuid: '6Mgm56X1GxH2BmluCec0..',
        desc: '<ul><li>Assesses how well you recognize, understand, express, and use emotions at work, as well as your capacity for empathy.</li></ul>',
      },
      {
        name: 'narcissism, machiavellianism, and psychopathy',
        id: 'qbmT7jOtHGibfCNEAFPmkF',
        templateGuid: 'J_klZWOUFmedGDs6Rc.lUF',
        desc: '<ul><li>Explore your standing on the "dark triad", three subclinical traits that heavily influence how well you relate, work with, and lead others. </li></ul>',
      },
      {
        name: 'procrastination vs Self-Motivation',
        id: 'tklRdApLIsD2fxW3Sejq2F',
        templateGuid: '0QKi78XEFRmHpwp83ALSBk',
        desc: `<ul>
        <li>Do you always leave things to the last minute or are you a highly motivated individual?</li>
        <li>Discover your standing on these four "personal motivation" assessments:
        <ul>
          <li>Procrastination</li>
          <li>Proactive Personality</li>
          <li>Goal orientation</li>
          <li>Regulatory Focus</li>
        </ul></li></ul>`,
      },
      {
        name: 'grit and resilience',
        id: 'LhThn76LFpTnzXvnr44RNF',
        templateGuid: 'r8jz_2itG4PsWgSeknvIx.',
        desc: `
        <ul>
          <li>
            Assesses your standing on four personal characteristics that drive and sustain performance:
            <ul>
              <li>GRIT</li>
              <li>Self-control capacity</li>
              <li>Core self-evaluations</li>
              <li>Psychological capital</li>
            </ul>
          </li>
        </ul>
        `,
      },
    ],
  },
  {
    id: 'rel',
    name: 'relationship builder',
    styleName: 'relationship',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    assessments: [
      {
        name: 'conflict manager style',
        id: 'uYEGdX5QHi9EmDsjuZcCVV',
        templateGuid: 'T8z_lGxtIt9ZdwmlpvVY1.',
        desc: `<ul>
        <li>
          Assesses your tendency to use 5 strategies for dealing with conflict at work
          <ul>
            <li>Integrating</li>
            <li>Compromising</li>
            <li>Avoiding</li>
            <li>Dominating</li>
            <li>Accommodating</li>
          </ul>
        </li></ul>
        `,
      },
      {
        name: 'peer influencer',
        id: '6QhZukkwH4y7l0bmw52udF',
        templateGuid: 'oGNzYkCnIO1w73Wn37.6W.',
        desc: '<ul><li>Assesses your standing on a set of strategies that people use to effectively influence their peers/colleagues to carry out a personal request.</li></ul>* Bonus feature: You can invite others to rate you on this assessment',
      },
      {
        name: 'political skill and leading upwards',
        id: 'vZCBT.tlIJu6.ePMohcgok',
        templateGuid: 'RbUi8pj6GiT007W.8qQvp.',
        desc: `<ul>
          <li>Assesses your standing on four social skills necessary for navigating office politics.</li>
          <li>Assesses your propensity to monitor and adapt your behavior to the unique demands of different situations.</li>
          <li>Assesses your standing on a set of strategies that people use to effectively influence those above them at work.</li>
        </ul>`,
      },
      {
        name: 'impression management',
        id: 'j0QwgJ2mHyaXHDwa1t2yCF',
        templateGuid: 'INJaGAMqHlPSMtFaQwX8F.',
        desc: '<ul><li>Assesses your tendency to use five tactics designed to manage your image and how others see you – an essential toolkit for job interviews, promotions, and leadership opportunities.</li></ul>',
      },
    ],
  },
  {
    id: 'team_self',
    name: 'My Teams (Basic)',
    styleName: 'team-self',
    assessments: [
      {
        name: 'team health report',
        id: 'XFvZ89fJGX1pme.NZrfTKV',
        templateGuid: 'coN0KeSlI6yxmqXJCQW17k',
        desc: `
        <ul>
          <li>Assesses your personal views of your team's progress, including how well the team plans and executes its goals, coordinates its activities, and manages interpersonal relationships.</li>
          <li>To get the most of this diagnostic tool, all members of your team should complete this assessment. Contact us to unlock premium team management features.</li>
        </ul>
        `,
      },
      {
        name: 'team effectiveness',
        id: 'GIcqBy0SFlPQCtewmhwjG.',
        templateGuid: 'VsLq6YXUHFaQxPK_eZehaV',
        desc: `
        <ul>
          <li>Assesses your views of your team's performance following major project milestones and/or at the end of the project.</li>
          <li>To get the most of this exercise, all members of your team should complete this assessment. Contact us to unlock premium team management features.</li>
        </ul>
        `,
      },
    ],
  },
  {
    id: 'team',
    name: 'team player',
    styleName: 'team',
    assessments: [],
  },
  {
    id: 'cohorts',
    name: 'teams management',
    styleName: 'team',
    assessments: [],
  },
  {
    id: 'leader',
    name: 'leading others',
    styleName: 'leader',
    assessments: [
      {
        name: 'leadership foundations',
        id: 'zJ8sYl84Fs57pQTX9xaDvk',
        templateGuid: 'kVvNmPy8H71kUjSd61mMIk',
        desc: `
        <ul>
          <li>Assesses your intrinsic motivations for seeking leadership roles - a first step towards leadership development and training.</li>
          <li>Assesses your standing on two foundational leadership behaviors:
            <ul>
              <li>Task-Oriented Leadership</li>
              <li>People-Oriented Leadership</li>
            </ul>
          </li>
        </ul>
        * Bonus feature: You can invite others to rate you on this assessment
        `,
      },
      {
        name: 'one-on-one leadership',
        id: 'vD2kYIVUGq1RikbMZiz5bk',
        templateGuid: '.iXUyVQkFFDcNHfQhIKgEk',
        desc: `
          <ul><li>Assesses the quality of your relationship with each employee work with.</li></ul>
          * Bonus feature: You can invite others to rate you on this assessment
        `,
      },
      {
        name: 'leading by inspiration',
        id: 'h9sww0CDFeairFDuNXWAMk',
        templateGuid: 'YE9psj0mHFDi9b3TMASndk',
        desc: `
          <ul>
            <li>
              Assesses your standing on three leadership styles that inspire employees:
              <ul>
                <li>Transformational or Charismatic leadership</li>
                <li>Servant Leadership</li>
                <li>Humble Leadership</li>
              </ul>
            </li>
          </ul>
        * Bonus feature: You can invite others to rate you on this assessment
        `,
      },
      {
        name: 'leading with virtue',
        id: 'RZ3P7HABI2HzdewJZ3OfXk',
        templateGuid: 'aMDI_GYRG6ThFiy07TslC.',
        desc: `
          <ul>
            <li>
              Assesses your standing on two virtuous leadership behaviors:
                  <ul>
                    <li>Ethical Leadership</li>
                    <li>Leader Trustworthiness</li>
                  </ul>
            </li>
          </ul>
          <ul>
            <li>
              Assesses your standing on two behaviors that derail leadership effectiveness:
              <ul>
                <li>Abusive Leadership</li>
                <li>Authoritarian Leadership</li>
              </ul>
            </li>
          </ul>
          * Bonus feature: You can invite others to rate you on this assessment
        `,
      },
      {
        name: 'leadership power and influence',
        id: '3XAyt5FYHAPeQi63WRbapV',
        templateGuid: 'n559IVfwIceDnsL3pP5KVF',
        desc: `
          <ul><li>
            Assesses your perceptions of how much power you have at work and how well you use different influence tactics to engage and direct your employees.
          </li></ul>
          * Bonus feature: You can invite others to rate you on this assessment
        `,
      },
      {
        name: 'political skill and leading upwards',
        id: 'vZCBT.tlIJu6.ePMohcgok',
        templateGuid: 'RbUi8pj6GiT007W.8qQvp.',
        desc: `
        <ul>
          <li>Assesses your standing on four social skills necessary for navigating office politics.</li>
          <li>Assesses your propensity to monitor and adapt your behavior to the unique demands of different situations.</li>
          <li>Assesses your standing on a set of strategies that people use to effectively influence those above them at work.</li>
        </ul>
        `,
      },
    ],
  },
  {
    id: 'hexaco',
    name: 'HEXACO',
    styleName: 'self',
    assessments: [
      {
        name: 'General HEXACO personality profile',
        id: '92Ob1BX3Gj5fxXeOCb1J7V',
        templateGuid: 'VK.mJtXvIPPyOPIAV4_3.V',
        disabled: true,
        desc: `
        <ul>
          <li>
            Discover your standings on the "Big 6" personality traits.
            <ul>
              <li><b>H</b>onesty-Humility, <b>E</b>motionality, e<b>X</b>traversion, <b>A</b>greeableness, <b>C</b>onscientiousness, and <b>O</b>penness.</li>
            </ul>
          </li>
          <li>These traits reflect your behavioral tendencies across different life situations.</li>
        </ul>
        `,
      },
      {
        name: 'HEXACO@Work Personality Profile',
        id: 'y9ixAPySFguyv3Aj5MY3LV',
        templateGuid: '5vY5kxU8GDi.QlHjBIzC8k',
        desc: `
        <ul>
          <li>Leadership begins with self awareness!</li>
          <li>Explore your behavioral tendencies at work from the lens of your work-specific personality attributes.</li>
          <li>
            Obtain insights into your standings on the "Big 6" personality dimensions, and a further breakdown into 24 specific traits.
            <ul>
              <li><b>H</b>onesty-Humility, <b>E</b>motionality, e<b>X</b>traversion, <b>A</b>greeableness, <b>C</b>onscientiousness, and <b>O</b>penness/Creativity.</li>
            </ul>
          </li>
        </ul>
        *** Plus: Get a more rounded understanding of your behavioral tendencies at work by inviting others to rate you on this assessment!
        `,
      },
    ],
  },
]);
