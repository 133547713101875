var app=angular.module('hcap-analytics',[
	'ui.router',
	'ngAnimate',
	'ngTouch',
	'ngSanitize',
	'ngCsv',
	'oitozero.ngSweetAlert',
	'angular-js-xlsx',
	'LocalStorageModule',
	'ui.router.tabs',
	'xeditable',
	'ui.sortable',
	'disableAll',
	'LocalStorageModule',
	'ui.bootstrap',
])
angular.element(function() {
	angular.bootstrap(document, ['hcap-analytics'])
})

window.prod = true;