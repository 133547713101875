angular.module('hcap-analytics')
.controller('NavBarCtrl', ['$scope','$http','$state','UserService','$stateParams', function($scope,$http,$state,UserService,$stateParams) {

	$scope.isActive = function (state) {
		return $state.current.name==state
	}

	$scope.isAdmin = function(){
		return UserService.getUser().role=="Administrator"
	}

	$scope.isUser = function(){
		return UserService.getUser().role=="User"
	}

	$scope.isParticipant = function(){
		return $state.current.name=="participant" && $stateParams.participantId && $stateParams.participantId.length>0
	}

    $scope.isSignedIn = function() {
        return UserService.isSignedIn()
    }

	$scope.signOut=function(){
		UserService.signOut();
	}

}])
