angular.module('hcap-analytics')
.controller('ParticipateCtrlv2', [
'$scope',
'$location',
'$http',
'$state',
'$stateParams',
'$q',
'AlertService',
'TemplateService',
'localStorageService',
'$window',
'ParticipantService',
'UserService',
'AssessmentService',
'AssessmentStatsService',
'DevPlanService',
'sectionsConfig',
'$transitions',
'$anchorScroll',
'$timeout',
function(
  $scope,
  $location,
  $http,
  $state,
  $stateParams,
  $q,
  AlertService,
  TemplateService,
  localStorageService,
  $window,
  ParticipantService,
  UserService,
  AssessmentService,
  AssessmentStatsService,
  DevPlanService,
  sectionsConfig,
  $transitions,
  $anchorScroll,
  $timeout,
) {

  $scope.templateId = _.get($scope, `participateData.templateId`) || $stateParams.templateId;
  $scope.participantId = _.get($scope, `participateData.participantId`) || $stateParams.participantId;

  $scope.pageIndex = Number($stateParams.pageIndex) || 0;
  $scope.pageCount = null;
  $scope.pageProgressPerentage = 0;
  $scope.itemCount = 0;

  $scope.template = null;
  $scope.assessment = null;
  $scope.participant = null;
  $scope.scoring = null;
  $scope.citations = null;
  $scope.notes = null;
  $scope.stats = null;
  $scope.user = UserService.getUser();
  $scope.hideRaterPrompt = $stateParams.showRaterPrompt ? false : true;
  $scope.subsectionState = {};
  $scope.loaded = false;
  $scope.teamSelf = ['coN0KeSlI6yxmqXJCQW17k', 'VsLq6YXUHFaQxPK_eZehaV'].includes($scope.templateId);

  let initDesignatedRaterCount = -1;
  const hiding = $scope.templateId === '5vY5kxU8GDi.QlHjBIzC8k';
  $scope.showResults = !hiding;

  const feedbackState = JSON.parse(localStorage.getItem('feedbackState'));
  $scope.reqFeedbackState = feedbackState !== null ? feedbackState : true;

  $scope.newDesignatedRater = {
    name: '',
    email: '',
  };

  $scope.data = {
    section: { styleName: null },
    title: null,
  };

  let contextDefer = $q.defer();

  $scope.context = {
    loaded: null,
    devPlan: null,
    devPlanIds: null,
    section: null,
    restrictTo: null,
    goDevPlan: false,
    ...$scope.context,
  };

  DevPlanService.get().then(devPlan => {
    $scope.context.devPlan = devPlan;
  });

  $scope.updateTab = name => {
    $scope.currentTab = (name || $state.$current.name) === 'participate.result' ? 'result' : 'plan';
  };

  $scope.selectTab = function(tabName) {
    if (tabName !== $scope.currentTab) {
      $scope.currentTab = tabName;
      $state.go('participate.' + tabName);
    }
  };

  $scope.goDevPlan = () => {
    $state.go('dev-plan');
  };

  $scope.inPlan = function(key) {
    return $scope.context.devPlan && $scope.context.devPlan.goals.some(g => g.scaleId && g.scaleId === key);
  };

  $scope.addToPlan = function(key) {
    DevPlanService.newGoal($scope.context.devPlan, {
      scaleId: key,
    });
    DevPlanService.update($scope.context.devPlan);
  };

  $scope.shouldHideSection = function(section) {
    return !section.scores && !section.score && section.items.every(i => !i.score);
  };

  $scope.loadParticipant = () => {
    contextDefer = $q.defer();
    $scope.context.loaded = contextDefer.promise;

    if ($scope.participantId == 'preview') {
      TemplateService.get($scope.templateId, function(template) {
        $scope.template = template;
        $scope.responses = JSON.parse(localStorageService.get($scope.templateId)) || {};
        loadInternal();
      });
    } else if ($scope.participantId == 'preview_designated_rater') {
      TemplateService.get($scope.templateId, function(template) {
        template.questions = template.designatedRaterQuestions;
        $scope.template = template;
        $scope.responses = JSON.parse(localStorageService.get($scope.templateId)) || {};
        loadInternal();
      });
    } else {
      AssessmentStatsService.get(data => {
        AssessmentService.getIds(assessmentIds => {
          ParticipantService.get($scope.participantId, _.get($scope, 'participateData.orgView'), function(response) {
            $scope.assessment = response.assessment;
            $scope.data.title = $scope.assessment.name;

            const sectionId = localStorage.getItem('sectionSelected');
            if (response.scoring) {
              response.scoring.self.sections.forEach(s => {
                s.items.forEach(i => i.id = i.id || i.name);
              });
            }

            $scope.data.section = (sectionId && sectionsConfig.find(s => s.id === sectionId)) ||
              sectionsConfig.find(section => section.assessments.find(a => a.id === $scope.assessment.guid));
            $scope.context.section = $scope.data.section;
            $scope.template = response.template;

            $scope.participant = response.participant;
            $scope.responses = response.participant.responses;
            $scope.designatedRaters = response.designatedRaters;
            $scope.scoring = response.scoring;

            if (hiding) {
              if (-1 === initDesignatedRaterCount) {
                initDesignatedRaterCount = $scope.designatedRaters.length;
              }

              if ($scope.designatedRaters.length) {
                $scope.showResults = true;

                if (0 === initDesignatedRaterCount) {
                  $scope.toggleFeedbackState(true);
                  $timeout(() => { $anchorScroll('participate-result') });
                }
              }
            }

            if ($scope.scoring && $scope.scoring.self) {
              $scope.scoring.self.sections.filter(s => !s.hidden).forEach(s => {
                if (s.subDesc) {
                  $scope.subsectionState[s.id + 'subDesc'] = true;
                }

                s.items.filter(i => !i.hidden && i.scoreTotal).forEach((i, index) => {
                  $scope.subsectionState[i.id] = !!($scope.template.oneOnOne || !s.scoreTotal && index < 4);
                });
              });
            }

            const assessment = assessmentIds.find(e => e.templateGuid === $scope.assessment.template_guid);
            if (assessment) {
              $scope.context.devPlanIds = assessment.sections.reduce((acc, section) => [
                ...acc,
                section.id,
                ...(section.items || []).map(item => item.id),
              ], []);
            }

            if ($scope.participant.designatedRater) {
              $scope.responses['designatorFullName'] = _.startCase($scope.participant.designatorName);
              $scope.responses['designatorName'] = _.startCase($scope.participant.designatorName.trim().split(' ')[0]);
              $scope.template.questions = $scope.template.designatedRaterQuestions;
              $scope.data.title = 'Feedback for ' + $scope.responses['designatorName'];
            }

            $scope.stats = data.stats.find(stat => stat.guid === $scope.assessment.guid);

            $scope.notes = [];
            $scope.citations = [];
            const sections = _.get($scope.scoring, 'self.sections', []);
            sections.forEach(function(section) {
              if (section.citation && section.citation.trim().length > 0) {
                $scope.citations.push(section.citation.trim());
              }

              if (section.notes) {
                $scope.notes.push(section.notes);
              }

              const items = _.get(section, 'items');
              items.forEach(function(item) {
                if (item.citation) {
                  $scope.notes.push(item.notes);
                }
              });
            });
            const extraCitations = _.get($scope.scoring, 'self.citations', []);
            $scope.citations = $scope.citations.concat(extraCitations);
            $scope.citations = _.uniq($scope.citations);

            $scope.showLegend = !!$scope.template.oneOnOne;
            if (!$scope.showLegend && $scope.scoring && $scope.scoring.self && $scope.scoring.self.sections) {
              $scope.scoring.self.sections.forEach(function(section) {
                if ($scope.scoreLeft(section) && $scope.scoreRight(section)) {
                  $scope.showLegend = true;
                }
                section.items = section.items || [];
                section.items.forEach(function(item) {
                  if ($scope.scoreLeft(section, item) && $scope.scoreRight(section, item)) {
                    $scope.showLegend = true;
                  }
                });
              });
            }

            if ($scope.scoring && $scope.scoring.self) {
              // angular requires defined arrays for reactivity
              $scope.scoring.self.sections.forEach((section, i) => {
                const employees = section.employees;

                if (section.scoreTotal) {
                  section.display = {
                    marks: $scope.scoreMarks(section, null, i, null, true),
                    legendMarkTexts: $scope.legendMarkTexts(section, i),
                    legendRangeText: $scope.legendRangeText(section, i),
                    legendSampleText: $scope.sampleText(section.sample, i, null, $scope.template, employees) || '',
                    startRange: $scope.scoreStartRange(section, null, i),
                    endRange: $scope.scoreEndRange(section, null, i),
                    maxScore: section.scoreTotal,
                    types: $scope.scoreTypes(section, null, i, null, true),
                  };
                }

                if (section.items) {
                  section.items.forEach((item, j) => {
                    if (item.scoreTotal) {
                      try {
                        item.display = {
                          marks: $scope.scoreMarks(section, item, i, j, !$scope.template.oneOnOne),
                          legendMarkTexts: $scope.legendMarkTexts(item, i, j),
                          legendRangeText: $scope.legendRangeText(item, i, j),
                          legendSampleText: $scope.sampleText(item.sample, i, j, $scope.template, employees) || '',
                          startRange: $scope.scoreStartRange(section, item, i, j),
                          endRange: $scope.scoreEndRange(section, item, i, j),
                          maxScore: item.scoreTotal,
                          types: $scope.scoreTypes(section, item, i, j, !$scope.template.oneOnOne),
                        };
                      } catch (e) {
                        console.log(e);
                      }
                    }
                  });
                }
              });
            }

            loadInternal();
          });
        });
      });
    }

    return contextDefer.promise;
  };

  function loadInternal() {
    //var pageIndex = 0;
    //$scope.template.questions.forEach(function(question) {
    //question.pageIndex = pageIndex;
    //if( question.type == 'page_break' ) {
    //pageIndex++
    //} else if(question.type != 'caption') {
    //$scope.itemCount++;
    //}
    //})
    //$scope.pageCount = pageIndex + 1;
    //watchResponses();

    contextDefer.resolve();
    $scope.loaded = true;
  }

  $transitions.onEnter({entering: 'participate.**'}, (t, s) => {
    $scope.updateTab(s.name);
  });

  $scope.resetAddDesignatedRaterForm = function() {
    $scope.newDesignatedRater = {
      name: '',
      email: '',
    };
  };

  $scope.addDesignatedRater = function(newDesignatedRaterForm) {
    if (newDesignatedRaterForm.$invalid) {
      AlertService.warning('Invalid name or email address.');
      return;
    } else if ($scope.participant.email.trim() === $scope.newDesignatedRater.email.trim()) {
      AlertService.warning('You cannot use yourself as feedback provider. Please request feedback from a colleague');
      return;
    }

    const obj = _.clone($scope.newDesignatedRater);
    obj.assessmentName = $scope.assessment.name + ' [' + $scope.participant.name + '-' + $scope.participant.email + ']';
    obj.email = obj.email.toLowerCase().trim();

    $http.post('./api/participant/' + $scope.participantId + '/designatedRater', obj).then(function(response) {
      if (response.data.success) {
        $scope.resetAddDesignatedRaterForm();
        AlertService.alert('An email invitation has been sent to ' + obj.email + '.', null, function() {
          localStorage.removeItem('src');
          $scope.loadParticipant();
        });
      } else {
        AlertService.warning(response.data.message, 'Error');
      }
    }, function(error) {
      console.log(error.data);
    });
  };

  $scope.sendDesignatedRaterNotification = function(designatedRater) {
    AlertService.confirm('Are you sure you want to resend the notification?', 'Resend Notification', function(choice) {
      if (choice) {
        ParticipantService.sendDesignatedRaterNotification($scope.participantId, designatedRater.email.toLowerCase(), function(ret) {
          if (ret.success) {

          } else {
            AlertService.warning('Unable to resend notification', ret.message);
          }
        });
      }
    });
  };

  $scope.formatScore = function(str) {
    const num = Number(str);
    if (isNaN(num)) {
      return '';
    }
    return num.toFixed(1);
  };

  $scope.scoreMean = (section, subsection) => {
    if ($scope.stats && _.get($scope, 'template.liveData')) {
      const liveSection = $scope.stats.scoring.find(s => s.id === section.id);
      let mean = 0;

      if (liveSection) {
        mean = liveSection.mean || 0;

        if (subsection) {
          const liveSubsection = liveSection.items.find(s => s.id === subsection.id);
          mean = liveSubsection && liveSubsection.mean || mean;
        }
      }

      return mean;
    } else {
      const data = subsection || section;
      return _.get(data, 'mean') || 0;
    }
  };

  $scope.scoreMeanAdjusted = (section, subsection) => {
    const data = subsection || section;
    const scoreTotal = _.get(data, 'scoreTotal', 1);

    if ($scope.stats && _.get($scope, 'template.liveData')) {
      return $scope.scoreMean(data, subsection) / scoreTotal * 100;
    } else {
      const mean = _.get(data, 'mean') || 0;
      return _.round(mean / scoreTotal * 100, 2);
    }
  };

  $scope.scoreSd = (section, subsection) => {
    if ($scope.stats && _.get($scope, 'template.liveData')) {
      const liveSection = $scope.stats.scoring.find(s => s.id === section.id);
      let sd = 0;

      if (liveSection) {
        sd = liveSection.sd || 0;

        if (subsection) {
          const liveSubsection = liveSection.items.find(s => s.id === subsection.id);
          sd = liveSubsection && liveSubsection.sd || sd;
        }
      }

      return sd;
    } else {
      return 0;
    }
  };

  $scope.scoreMarks = (item, subItem, sectionIndex, itemIndex, useRaters) => {
    const data = subItem || item;

    if (data.rr) {
      if (_.get($scope, 'participateData.orgView')) {
        return data.scores.map(s => s.score);
      } else {
        return data.scores;
      }
    } else if (data.team) {
      if (data.leaderOnly && data.selfRate) {
        return data.scores;
      } else {
        return [data.score];
      }
    } else {
      const path = !_.isNil(itemIndex) ? `scoring.raters.sections[${sectionIndex}].items[${itemIndex}].score` : `scoring.raters.sections[${sectionIndex}].score`;
      const feedback = _.get($scope, path);
      if (!_.isNil(feedback) && useRaters) {
        return [
          data.score,
          feedback,
          $scope.scoreMean(item, subItem),
        ];
      } else {
        return [
          data.score,
          $scope.scoreMean(item, subItem),
        ];
      }
    }
  };

  $scope.scoreTypes = (item, subItem, sectionIndex, itemIndex, useRaters) => {
    const data = subItem || item;

    if (data.rr) {
      if (_.get($scope, 'participateData.orgView')) {
        return data.scores.map(s => 'variable');
      } else {
        return ['personal', 'others'];
      }
    } else if (data.team) {
      if (data.leaderOnly && data.selfRate) {
        return ['personal', 'others'];
      } else {
        return ['others'];
      }
    } else {
      const path = !_.isNil(itemIndex) ? `scoring.raters.sections[${sectionIndex}].items[${itemIndex}].score` : `scoring.raters.sections[${sectionIndex}].score`;
      const feedback = _.get($scope, path);
      if (!_.isNil(feedback) && useRaters) {
        return [
          'personal',
          'others',
          'pop',
        ];
      } else {
        return [
          'personal',
          'pop',
        ];
      }
    }
  };

  $scope.scoreStartRange = (item, subItem, sectionIndex, itemIndex) => {
    const data = subItem || item;

    if (data.rr || data.team) {
      return data.min;
    } else if ($scope.template.oneOnOne && !_.isNil(itemIndex)) {
      return null;
    } else {
      return $scope.scoreLeft(item, subItem);
    }
  };

  $scope.scoreEndRange = (item, subItem, sectionIndex, itemIndex) => {
    const data = subItem || item;

    if (data.rr || data.team) {
      return data.max;
    } else if ($scope.template.oneOnOne && !_.isNil(itemIndex)) {
      return null;
    } else {
      return $scope.scoreRight(item, subItem);
    }
  };

  $scope.legendRangeText = (container, sectionIndex, itemIndex) => {
    if (container.rr) {
      return 'Range of scores from teammates';
    } else if (container.team) {
      return 'Range of participant scores';
    } else if ($scope.template.oneOnOne) {
      return _.isNil(itemIndex) ? 'Range of relationship quality reported by others' : null;
    } else {
      return 'Range of scores for 95% of the population';
    }
  };

  $scope.legendMarkTexts = (container, sectionIndex, itemIndex) => {
    if (container.rr) {
      if (_.get($scope, 'participateData.orgView')) {
        return container.scores.map(s => `${_.get($scope.participateData.members.find(m => m._id === s.id), 'name', 'Missing particiant')} (${s.score})`);
      } else {
        return [
          'Self-rated score',
          'Average score from teammates',
        ];
      }
    } else if (container.team) {
      if (container.leaderOnly && container.selfRate) {
        return ['Self-rated score', 'Average team score'];
      } else {
        return ['Average team score'];
      }
    } else if ($scope.template.oneOnOne) {
      if (_.isNil(itemIndex)) {
        return [
          'Average self-reported one-on-one relationship quality',
          'Average one-on-one relationship quality reported by others',
        ];
      } else {
        return [
          `Your self-reported one-on-one relationship quality with ${container.name}`,
        ];
      }
    } else {
      const path = !_.isNil(itemIndex) ? `scoring.raters.sections[${sectionIndex}].items[${itemIndex}].score` : `scoring.raters.sections[${sectionIndex}].score`;
      const feedback = _.get($scope, path);
      if (!_.isNil(feedback)) {
        return [
          'Your Score',
          'Feedback from Others',
          'Population Average',
        ];
      } else {
        return [
          'Your Score',
          'Population Average',
        ];
      }
    }
  };

  $scope.scoreLeft = (section, subsection) => {
    const data = subsection || section;

    if (_.get($scope, 'template.liveData')) {
      const mean = $scope.scoreMean(section, subsection);
      const sd = $scope.scoreSd(section, subsection);

      return Math.max(mean - 2*sd, 0);
    } else {
      return data.mean && data.sd && Math.max(0, _.round(data.mean - 2*data.sd, 2)) || 0
    }
  };

  $scope.scoreRight = (section, subsection) => {
    const data = subsection || section;

    if (_.get($scope, 'template.liveData')) {
      const mean = $scope.scoreMean(section, subsection);
      const sd = $scope.scoreSd(section, subsection);

      return Math.min(mean + 2*sd, data.scoreTotal);
    } else {
      return data.mean && data.sd && Math.min(data.scoreTotal, _.round(data.mean + 2*data.sd, 2)) || 0
    }
  };

  $scope.tabClasses = function(tab) {
    if ($scope.currentTab == tab) {
      return [
        'active',
        $scope.data.section.styleName,
      ];
    } else {
      return [
        $scope.data.section.styleName,
        'bg-' + $scope.data.section.styleName + '-muted',
      ];
    }
  };

  $scope.toggleSubsection = id => {
    $scope.subsectionState[id] = !$scope.subsectionState[id];
  };

  $scope.sampleText = (sample, sectionIndex, itemIndex, template, employees) => {
    const scale = _.get($scope, `stats.scoring[${sectionIndex}].items[${itemIndex}]`) || _.get($scope, `stats.scoring[${sectionIndex}]`) || {};

    if (template.liveData) {
      if (employees) {
        return `Population: ${scale.count} Hcap analytics users as rated by ${scale.designatedCount} employees`;
      } else {
        return `Population: ${scale.count} Hcap analytics users`;
      }
    } else {
      return sample;
    };
  };

  $scope.print = () => {
    window.print();
  };

  $scope.toggleFeedbackState = (noStore) => {
    $scope.reqFeedbackState = !$scope.reqFeedbackState;

    if (!noStore) {
      localStorage.setItem('feedbackState', JSON.stringify($scope.reqFeedbackState));
    }
  };

  $scope.onFinishQuestions = $scope.loadParticipant; // hack

  $scope.loadParticipant();
  $scope.updateTab();
}]);
