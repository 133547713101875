angular.module('hcap-analytics')
.directive('fileReader', function() {
	return {
		scope: {
			fileReader:"="
		},
		link: function(scope, element, attrs) {
			$(element).on('change', function(changeEvent) {
				var files = changeEvent.target.files
				if (files.length) {
					var r = new FileReader()
					r.onload = function(e) {
						var contents = e.target.result
						scope.$apply(function () {
							scope.fileReader = contents
						})
					}

					r.readAsText(files[0])
				}
			})
		}
	}
})
.directive('copyToClipboard',  function ($window) {
    var body = angular.element($window.document.body)
    var textarea = angular.element('<textarea/>')
    textarea.css({
        position: 'fixed',
        opacity: '0'
    })

    function copy(toCopy) {
        textarea.val(toCopy)
        body.append(textarea)
        textarea[0].select()

        try {
            var successful = document.execCommand('copy')
            if (!successful) throw successful
        } catch (err) {
            console.log("failed to copy", toCopy)
        }
        textarea.remove()
    }

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.bind('click', function (e) {
                copy(attrs.copyToClipboard)
            })
        }
    }
})
