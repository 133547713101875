angular.module('hcap-analytics')
.controller('GroupListCtrl', ['$scope','$http','$state','AlertService','GroupService', function($scope,$http,$state,AlertService,GroupService) {

	$scope.groups = null

	$scope.createGroup = function() {
		GroupService.create().then(function(guid) {
			if(guid) {
				location.href = 'v1/group/'+guid;
			}
		})
	}

	$scope.getAll = function() {
		GroupService.getAll(function(groups){
			groups.forEach(function(asssessment){
				asssessment.questions = asssessment.questions || [];
			})
			$scope.groups = groups
		})
	}

	$scope.deleteGroup = function(guid) {
		AlertService.confirm("Are you sure you want to delete this group?", null, function(choice){
			if(choice) {
				GroupService.delete(guid).then(function(guid) {
					$scope.getAll();
				})
			}
		})
	}

	$scope.getAll()
}])
