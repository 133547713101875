angular.module('hcap-analytics').controller('DevPlanCtrl', [
  '$scope',
  '$http',
  '$state',
  '$stateParams',
  '$uibModal',
  '$q',
  'DevPlanService',
  'AssessmentService',
  'UserService',
  'TemplateService',
function(
  $scope,
  $http,
  $state,
  $stateParams,
  $uibModal,
  $q,
  DevPlanService,
  AssessmentService,
  UserService,
  TemplateService,
) {
  $scope.now = new Date();
  const activityCategories = [
    { value: 0, text: 'On-the-job action learning'},
    { value: 1, text: 'Coaching'},
    { value: 2, text: 'Mentorship'},
    { value: 3, text: 'Formal course (e.g., university, college, tech)'},
    { value: 4, text: 'In-house course (i.e., through work)'},
    { value: 5, text: 'Online training modules (through work)'},
    { value: 6, text: 'Online training modules (non-work)'},
    { value: 7, text: 'Leadership development program (through work)'},
    { value: 8, text: 'Training Seminars'},
    { value: 9, text: 'Self-help books/podcasts/blogs/etc'},
    { value: 10, text: 'Training/development activities through a third party vendor'},
    { value: 11, text: '360 Feedback (through hcap-analytics)'},
    { value: 12, text: '360 Feedback (other work or nonwork platform)'},
    { value: 13, text: 'Stretch assignments (through work)'},
    { value: 14, text: 'Networking'},
    { value: 15, text: 'Other'},
    { value: 16, text: 'Book'},
    { value: 17, text: 'Podcast'},
    { value: 18, text: 'Seminar'},
    { value: 19, text: 'Video'},
    { value: 20, text: 'Workbook'},
    { value: 21, text: 'Article'},
    { value: 22, text: 'Action Learning'},
  ];
  $scope.data = {
    minYear: $scope.now.getFullYear(),
    maxYear: $scope.now.getFullYear() + 2,
    activityCategories,
    engagementCats: [
      { value: 4, text: 'A Great Deal'},
      { value: 3, text: 'A Lot'},
      { value: 2, text: 'A Moderate Amount'},
      { value: 1, text: 'A Little'},
      { value: 0, text: 'None At All'},
    ],
    efficacyCats: [
      { value: 4, text: 'Extremely Effective'},
      { value: 3, text: 'Very Effective'},
      { value: 2, text: 'Moderately Effective'},
      { value: 1, text: 'Slightly Effective'},
      { value: 0, text: 'Not Effective At All'},
    ],
    devPlan: {
      introed: {},
      plan: null,
      suggests: [],
    },
    section: {
      styleName: null,
    },
    allowedGoals: null,
    goalOptions: [],
    readOnly: false,
    mentorState: true,
    shares: [],
    sharee: null,
    title: null,
  };

  $scope.loadShares = (force = false) => {
    return DevPlanService.getShares(force).then((shares) => {
      $scope.data.shares = shares;
    }).catch(() => {});
  };

  const loadingPromises = {
    templates: TemplateService.getAll(),
    loadShares: $scope.loadShares(true),
  };

  if (_.get($scope, 'context.loaded')) {
    $scope.showHeader = true;
    $scope.goalPlaceholder = 'Select an Attribute/Behavior to work on';

    loadingPromises.allowedGoals = _.get($scope, 'context.loaded').then(() => {
      $scope.data.allowedGoals = $scope.context.devPlanIds;
      $scope.section = $scope.context.section;
    });
  } else {
    $scope.showHeader = false;
    $scope.context = {};
    $scope.goalPlaceholder = 'Select an Attribute/Behavior or Create a Custom Plan';

    loadingPromises.devPlan = DevPlanService.get($stateParams.guid);

    $scope.data.readOnly = !!$stateParams.guid;
  }

  $q.all(loadingPromises).then(res => {
    $scope.resetSharee();

    const templates = res.templates;

    if (res.devPlan) {
      $scope.data.devPlan.plan = res.devPlan;
    }

    $scope.updateSuggests();

    AssessmentService.getIds(templateInfo => {
      $scope.data.goalOptions = [
        { value: 'CUSTOM', text: 'Custom Plan' },
      ];

      templateInfo.forEach(ti => {
        const template = templates.find(t => ti.templateGuid === t.guid);

        if (template) {
          const isWork = template.permissions.includes('atwork');

          ti.sections.forEach(section => {
            if (section.id) {
              $scope.data.goalOptions.push({
                value: section.id,
                text: section.devPlanName || section.name,
                isWork,
                templateGuid: template.guid,
              });
            }

            section.items.forEach(i => $scope.data.goalOptions.push({
              value: i.id,
              text: i.devPlanName || i.name,
              isWork,
              templateGuid: template.guid,
            }));
          });
        }
      });

      if ($scope.data.allowedGoals) {
        $scope.data.goalOptions = $scope.data.goalOptions.filter(o => $scope.data.allowedGoals.includes(o.value));
      }
    });

    $scope.data.devPlan.introed = $scope.goals().reduce((acc, goal) => {
      if ($scope.goalIntroed(goal)) {
        acc[goal.scaleId] = true;
      }

      return acc;
    }, {});
  });

  $scope.devPlan = () => {
    return $scope.context.devPlan || $scope.data.devPlan.plan;
  };

  $scope.goals = () => {
    const goals = [...$scope.devPlan().goals].sort((a, b) => {
      if (!a.complete && b.complete) {
        return -1;
      } else if (a.complete && !b.complete) {
        return 1;
      } else {
        return 0;
      }
    });

    if ($scope.data.allowedGoals) {
      return goals.filter(g => !g.scaleId || $scope.data.allowedGoals.includes(g.scaleId));
    }

    return goals;
  };

  $scope.isGoalAtWork = goal => {
    return $scope.data.goalOptions.find(go => go.isWork && go.value === goal.scaleId);
  };

  $scope.goToAssessment = goal => {
    const templateGuid = $scope.data.goalOptions.find(go => go.value === goal.scaleId).templateGuid;
    AssessmentService.getUserSelfAssessments().then(assessments => {
      const assessment = assessments.find(assessment => assessment.template_guid === templateGuid);

      $state.go('participate.result', {
        templateId: templateGuid,
        participantId: assessment.participant.guid,
      });
    });
  };

  $scope.suggestIsInvite = activity => {
    return activity.categoryId === 11;
  };

  $scope.goalOption = function(scaleId) {
    return scaleId &&
      $scope.data.goalOptions &&
      $scope.data.goalOptions.find(o => o.value === scaleId);
  };

  $scope.toLetter = index => {
    return String.fromCharCode('a'.charCodeAt() + index);
  };

  $scope.onUpdate = _.debounce(() => {
    DevPlanService.update($scope.devPlan());
  }, 500);

  $scope.onScaleUpdate = _.debounce(() => {
    DevPlanService.update($scope.devPlan());
    $scope.updateSuggests();
  }, 500);

  $scope.updateSuggests = () => {
    DevPlanService.getSuggestsFromGoals($scope.devPlan().goals).then(suggests => {
      $scope.data.devPlan.suggests = suggests;
    });
  };

  $scope.newGoal = () => {
    DevPlanService.newGoal($scope.devPlan());
    $scope.onUpdate();
  };

  $scope.newActivity = goal => {
    DevPlanService.newActivity(goal);
    $scope.onUpdate();
  };

  $scope.deleteGoal = index => {
    DevPlanService.deleteGoal($scope.devPlan(), $scope.goals()[index]);
    $scope.onUpdate();
  };

  $scope.deleteActivity = (goal, index) => {
    DevPlanService.deleteActivity(goal, index);
    $scope.onUpdate();
  };

  $scope.deleteResult = activity => {
    DevPlanService.deleteResult(activity);
    $scope.onUpdate();
  };

  $scope.dateInit = (parent, name) => {
    if (!parent[name]) {
      parent[name] = new Date();
    }
  };

  $scope.goalCanComplete = goal => {
    return (
      goal.activities.length > 0 &&
      goal.activities.find(a => {
        return !(
          a.text &&
          a.result &&
          typeof a.categoryId === 'number' &&
          a.dateStart &&
          a.dateEnd &&
          a.efficacy &&
          a.engagement
        );
      }) === undefined &&
      goal.dateStart &&
      goal.dateEnd &&
      goal.text &&
      goal.scaleId && (
        goal.scaleId !== 'CUSTOM' || (
          goal.scaleId === 'CUSTOM' &&
          goal.customPlan
        )
      )
    );
  };

  $scope.toggleGoalComplete = goal => {
    goal.complete = !goal.complete;
    $scope.onUpdate();
  };

  $scope.print = () => {
    window.print();
  };

  $scope.calendarInvite = (goal) => {
    const option = $scope.goalOption(goal.scaleId);
    const goalName = option && option.text;
    const startDate = moment(goal.dateStart).format('YYYY-MM-DD');
    const endDate = moment(goal.dateEnd).format('YYYY-MM-DD');
    const name = `Hcap Analytics Goal: Work on ${goalName || 'Development Goal'}`;

    atcb_action({
      name: name,
      startDate: startDate,
      endDate: endDate,
      options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams', 'Yahoo'],
      iCalFileName: 'dev-plan',
      trigger: 'click',
    });
  };

  $scope.pickSuggest = goal => {
    const suggests = DevPlanService.getSuggest($scope.data.devPlan.suggests, goal.scaleId);

    $uibModal.open({
      templateUrl: 'templates/partials/_pickSuggest.html',
      windowClass: 'pick-suggest-modal',
      controller: ['$uibModalInstance', '$scope', ($uibModalInstance, $scope) => {
        $scope.data = {
          suggests,
        };

        $scope.catText = catId => {
          const cat = activityCategories.find(c => c.value === catId);
          return cat && cat.text || '';
        };

        $scope.select = suggest => {
          DevPlanService.newActivity(goal, {
            text: suggest.title,
            categoryId: suggest.category,
            suggestId: suggest.id,
          });
          $uibModalInstance.dismiss('cancel');
        };

        $scope.close = () => {
          $uibModalInstance.dismiss('cancel');
        };
      }],
    });
  };

  $scope.getSuggestLink = suggestId => {
    const suggest = DevPlanService.getSuggestId($scope.data.devPlan.suggests, suggestId);
    return suggest && suggest.link;
  };

  $scope.goalIntroed = goal => {
    return goal.text || goal.activities.length || $scope.data.devPlan.introed[goal.scaleId];
  };

  $scope.introGoal = goal => {
    $scope.data.devPlan.introed[goal.scaleId] = true;
  };

  $scope.suggestExists = scaleId => {
    return DevPlanService.suggestExists($scope.data.devPlan.suggests, scaleId);
  };

  $scope.toggleMentor = () => {
    $scope.data.mentorState = !$scope.data.mentorState;

    localStorage.setItem('mentorState', JSON.stringify($scope.data.mentorState));
  };

  $scope.share = form => {
    if (form.$invalid) {
      return;
    }

    DevPlanService.share($scope.data.sharee).then(() => {
      $scope.loadShares(true);
      $scope.resetSharee();
    });
  };

  $scope.resetSharee = () => {
    $scope.data.sharee = {
      name: null,
      email: null,
    };
  };

  $scope.removeShare = (share) => {
    DevPlanService.shareDelete(share.guid).then(() => {
      $scope.loadShares(true);
    });
  };
}]);
