angular.module('hcap-analytics')
.config([
  '$stateProvider',
  '$urlRouterProvider',
  '$locationProvider',
  '$qProvider',
  '$transitionsProvider',
function(
  $stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $qProvider,
  $transitionsProvider,
) {
  $qProvider.errorOnUnhandledRejections(false);
  $locationProvider.html5Mode({
    enabled: true,
    rewriteLinks: false,
  });
  $locationProvider.hashPrefix('');

  $urlRouterProvider.otherwise('/v2');

  $transitionsProvider.onStart({}, trans => {
    if (trans) {
      const userService = trans.injector().get('UserService');
      const signedIn = userService.isSignedIn();

      if (trans.to().authenticate && !signedIn) {
        return trans.router.stateService.target('landing', {
          redirect: {
            state: trans.to().name,
            params: trans.params(),
          },
        });
      } else if (trans.to().name === 'signin' && signedIn) {
        userService.signOut();
      }
    } else {
      return true;
    }
  });

  $transitionsProvider.onBefore({to: 'participate.questions'}, trans => {
    if (!trans.params().asRater) {
      const userService = trans.injector().get('UserService');
      return userService.hasSetConsentStatus().then(hasSetConsent => {
        if (!hasSetConsent) {
          return trans.router.stateService.target('profile', {
            promptFill: true,
          });
        }
      });
    }
  });

  $stateProvider
    .state('landing', {
      url: '/',
      templateUrl: 'templates/landing.html',
      controller: 'LandingCtrl',
      params: {
        redirect: null,
      },
      redirectTo: trans => {
        const user = localStorage.getItem('user');
        const locationService = trans.injector().get('$location');
        const src = locationService.search().src;

        if (src && !user) {
          localStorage.setItem('src', src);
          return { state: 'landing-consent' };
        } else {
          return true;
        }
      },
    })
    .state('landing-consent', {
      url: '/consent',
      templateUrl: 'templates/landingConsent.html',
      controller: 'LandingConsentCtrl',
      params: {
        redirect: null,
      },
    })
    .state('home', {
      url: '/v2',
      redirectTo: trans => {
        const injector = trans.injector();
        const userService = injector.get('UserService');

        if (userService.isSignedIn()) {
          if (localStorage.getItem('src')) {
            const assessmentService = injector.get('AssessmentService');
            const sectionsConfig = injector.get('sectionsConfig');
            const util = injector.get('Util');

            return assessmentService.getUserSelfAssessments().then(assessments => {
              const atworkTemplateGuid = '5vY5kxU8GDi.QlHjBIzC8k';
              const section = sectionsConfig.find(section => section.id === 'hexaco');
              const assessmentData = section.assessments.find(a => a.templateGuid === atworkTemplateGuid);
              const part = assessments.find(a => a.template_guid === atworkTemplateGuid && a.participant)?.participant;
/*
              if (part && +new Date(part.startTime) < 1680648435300) {
                return { state: 'dashboard' };
              }
*/
              return util.viewAssessmentState(assessmentData.id, assessmentData.templateGuid, part);
            }).then(state => {
              return trans.router.stateService.target(state.state, state.params, state.options);
            });
          } else {
            return { state: 'dashboard' };
          }
        } else {
          return { state: 'landing' };
        }
      },
    })
    .state('dashboard', {
      url: '/v2/dashboard',
      templateUrl: 'templates/dashboard.html',
      controller: 'DashboardCtrl',
      authenticate: true,
    })
    .state('view-dev-plan', {
      url: '/v2/view-dev-plan?guid?email?code',
      templateUrl: 'templates/devPlans.html',
      redirectTo: trans => {
        const params = trans.params();

        if (!params.guid) {
          return { state: 'landing' };
        }

        return userPassthrough({
          params,
          userService: trans.injector().get('UserService'),
          routeData: {
            state: 'dev-plan',
            params: {
              guid: params.guid,
            },
          },
        });
      },
    })
    .state('dev-plan', {
      url: '/v2/dev-plan?guid',
      templateUrl: 'templates/devPlans.html',
      controller: 'DevPlanCtrl',
      authenticate: true,
    })
    .state('participate', {
      url: '/v2/participate/:templateId/:participantId',
      templateUrl: 'templates/participate_v2.html',
      controller: 'ParticipateCtrlv2',
      authenticate: true,
    })
    .state('participate.questions', {
      url: '/questions?pageIndex&showRaterPrompt',
      templateUrl: 'templates/questions.html',
      controller: 'QuestionsCtrl',
      authenticate: true,
      params: {
        asRater: null,
        finishRoute: null,
        finishRouteParams: null,
        onFinish: null,
      },
    })
    .state('participate.result', {
      url: '/result',
      templateUrl: 'templates/participateResult.html',
      authenticate: true,
    })
    .state('participate.plan', {
      url: '/plan',
      templateUrl: 'templates/devPlan.html',
      controller: 'DevPlanCtrl',
      authenticate: true,
    })
    .state('participate.rater-finish', {
      url: '/feedback-finished',
      templateUrl: 'templates/rater-finish.html',
      controller: 'RaterFinishCtrl',
      authenticate: true,
    })
    .state('sectionPage', {
      url: '/v2/section/:id',
      templateUrl: 'templates/section.html',
      controller: 'SectionCtrl',
      authenticate: true,
    })
    .state('profile', {
      url: '/v2/profile?require',
      templateUrl: 'templates/profile_v2.html',
      controller: 'ProfileCtrl',
      authenticate: true,
      params: {
        promptFill: null,
      },
    })
    .state('my-teams', {
      redirectTo: trans => {
        const userService = trans.injector().get('UserService');
        const cohortsService = trans.injector().get('CohortsService');

        if (userService.isOrganizer()) {
          return {
            state: 'cohorts',
          };
        } else {
          return cohortsService.summary().then(summaries => {
            if (summaries.length === 1) {
              const cohort = summaries[0];

              return {
                state: 'team',
                params: {
                  id: cohort._id,
                },
              };
            } else {
              return {
                state: 'teams',
              };
            }
          });
        }
      },
    })
    .state('teams', {
      url: '/v2/teams',
      templateUrl: 'templates/teams.html',
      controller: 'TeamsCtrl',
      authenticate: true,
    })
    .state('team', {
      url: '/v2/team/:id?phase',
      templateUrl: 'templates/team.html',
      controller: 'TeamCtrl',
      authenticate: true,
    })
    .state('team.questions', {
      url: '/questions?pageIndex',
      templateUrl: 'templates/questions.html',
      controller: 'QuestionsCtrl',
      authenticate: true,
      params: {
        templateId: null,
        participantId: null,
        loadRoute: null,
        finishRoute: null,
        finishRouteParams: null,
        teamMembers: null,
      },
    })
    .state('cohorts', {
      url: '/v2/cohorts',
      templateUrl: 'templates/cohorts.html',
      controller: 'CohortsCtrl',
      authenticate: true,
      redirectTo: trans => {
        const userService = trans.injector().get('UserService');
        if (userService.isOrganizer()) {
          return true;
        } else {
          return { state: 'dashboard' }
        }
      },
    })
    .state('activate', {
      url: '/v2/activate',
      templateUrl: 'templates/activate.html',
      controller: 'ActivateCtrl',
    })
    .state('rate', {
      url: '/v2/rate?email&code&templateId&participantId',
      redirectTo: trans => {
        const params = trans.params();

        if (!params.templateId || !params.participantId) {
          return { state: 'landing' };
        }

        return userPassthrough({
          params,
          userService: trans.injector().get('UserService'),
          routeData:{
            state: 'participate.questions',
            params: {
              asRater: true,
              templateId: params.templateId,
              participantId: params.participantId,
              finishRoute: 'participate.rater-finish',
              onFinish: trans.injector().get('InvitesService').update,
            },
          }
        });
      },
    })
    .state('cohort', {
      url: '/v2/cohort/:id',
      templateUrl: 'templates/cohort.html',
      controller: 'CohortCtrl',
      authenticate: true,
      redirectTo: trans => {
        const userService = trans.injector().get('UserService');

        if (userService.isOrganizer()) {
          return true;
        } else {
          return { state: 'dashboard' }
        }
      },
    })
    .state('sectionOrg', {
      url: '/v2/section-org',
      templateUrl: 'templates/sectionOrg.html',
      authenticate: true,
    })
    .state('teamNext', {
      url: '/v2/teams-next',
      templateUrl: 'templates/teamNext.html',
      controller: 'TeamNextCtrl',
      authenticate: true,
      redirectTo: trans => {
        const userService = trans.injector().get('UserService');

        if (userService.hasTeams() || userService.isOrganizer()) {
          return true;
        } else {
          return {
            state: 'sectionPage',
            params: {
              id: 'team_self',
            },
          };
        }
      },
    })
    .state('invites', {
      url: '/v2/invites',
      templateUrl: 'templates/invites.html',
      controller: 'InvitesCtrl',
      authenticate: true,
    });

  function userPassthrough({ routeData, userService, params }) {
    if (userService.isSignedIn()) {
      // logged in, redirect
      return routeData;
    } else {
      if (params.code === 'null') {
        // full account exists but logged out, redirect on log in
        return {
          state: 'landing',
          params: {
            redirect: routeData,
          },
        };
      } else if (params.email) {
        return new Promise(resolve => {
          // attempt to login designated user and redirect
          userService.signIn({
            email: params.email,
            code: params.code,
          }, user => {
            if (user) {
              resolve(routeData);
            } else {
              resolve({ state: 'landing' });
            }
          });
        });
      } else {
        return { state: 'landing' };
      }
    }
  }
}]);
