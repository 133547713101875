angular.module('hcap-analytics')
.directive('sidebar', [
  '$location',
  '$state',
  'UserService',
  'AssessmentService',
  'InvitesService',
  'sectionsConfig',
  'DevPlanService',
  'Util',
  function(
    $location,
    $state,
    UserService,
    AssessmentService,
    InvitesService,
    sectionsConfig,
    DevPlanService,
    Util,
  ) {
    return {
      templateUrl: 'templates/sidebar.html',
      restrict: 'E',
      link: function($scope, $element, $attrs) {
        let allAssessments = [];

        const xs = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 834;
        $scope.pageNames = {
          home: 'My Dashboard',
          moral: 'Moral Character',
          self: 'Self Leadership',
          team_self: 'My Teams (Basic)',
          team: 'My Teams',
          cohorts: 'Teams Management',
          rel: 'Relationship Builder',
          leader: 'Leading Others',
          plans: 'My Development Plans',
          org: 'My Organization',
          profile: 'My Profile',
          invites: 'Pending Invites',
        };

        $scope.open = !xs;
        $scope.secondaryOpen = false;
        $scope.sectionSelected = localStorage.getItem('sectionSelected');
        $scope.secondarySection = null;
        $scope.secondarySectionId = null;
        $scope.assessments = [];
        $scope.isOrganizer = UserService.isOrganizer();
        $scope.noticeCount = 0;
        $scope.going = false;

        AssessmentService.getUserSelfAssessments().then(function(assessments) {
          allAssessments = assessments;

          if ($scope.secondarySectionId) {
            $scope.updateAssessments($scope.secondarySectionId);
          }
        });

        $scope.$watch(() => InvitesService.noticeCount, newVal => {
          $scope.noticeCount = newVal;
        });

        $scope.toggleMain = function() {
          $scope.open = !$scope.open;

          if (!$scope.open) {
            $scope.secondaryOpen = false;
          }
        };

        $scope.openSecondary = function(section) {
          $scope.secondaryOpen = true;
          $scope.updateAssessments(section);
        };

        $scope.closeSecondary = function() {
          $scope.secondaryOpen = false;
        };

        $scope.signOut = function() {
          UserService.signOut();
        };

        $scope.select = function(section) {
          try {
            localStorage.setItem('sectionSelected', section);
          } catch (e) {}
          $scope.sectionSelected = section;
          $scope.closeSecondary();
          $scope.updateTitle();

          if (xs) {
            $scope.open = false;
          }
        };

        $scope.titleSelect = () => {
          if (!xs) {
            $scope.select('home');
            $state.go('dashboard');
          }
        };

        $scope.updateTitle = () => {
          $scope.sectionTitle = !xs ? 'My Dashboard' : $scope.pageNames[$scope.sectionSelected];
        };

        $scope.assessmentCompleted = (assessment) => {
          return assessment.participant && assessment.participant.completed;
        };

        $scope.updateAssessments = section => {
          $scope.secondarySection = sectionsConfig.find(s => s.id === section);

          $scope.assessments = $scope.secondarySection.assessments.reduce((acc, a) => {
            if (a.section) {
              return [
                ...acc,
                ...sectionsConfig.find(s => s.id === a.section).assessments,
              ];
            } else {
              return [...acc, a];
            }
          }, []).map(a => {
            const assessment = allAssessments.find(aa => aa.guid === a.id && aa.participant);
            const disabled = a.disabled && (!assessment || !$scope.assessmentCompleted(assessment));

            return {
              ...assessment,
              ...a,
              disabled,
            };
          });

          $scope.secondarySectionId = section;
        };

        $scope.assessmentClick = (assessment) => {
          if (!$scope.going) {
            $scope.going = true;
            $scope.select($scope.secondarySectionId);

            Util.viewAssessment(assessment.id, assessment.templateGuid || assessment.template_guid, assessment.participant).finally(() => {
              $scope.going = false;
            });
          }
        };

        $scope.assessmentClass = (a) => {
          return a.disabled ? 'text-grey' : 'text-' + $scope.secondarySection.styleName;
        };

        (async () => {
          const plan = await DevPlanService.get();
          const stats = DevPlanService.stats(plan);
          $scope.plansStarted = stats.started;
          $scope.plansCompleted = stats.completed;
        })();

        $scope.name = UserService.getUser()?.name;

        $scope.updateTitle();
        InvitesService.update();
      },
    };
  },
]);
