angular.module('hcap-analytics')
  .service('AlertService', ['SweetAlert', function(SweetAlert) {
    this.warning = function(message, title, onComplete) {
      if (!title) {
        title = '';
      }
      SweetAlert.swal({
        title: title,
        text: message,
        type: 'warning',
        confirmButtonColor: '#f39c12'},
        function(choice) {
          if (onComplete) {
            onComplete(choice);
          }
        }
      );
    };

    this.alert = function(message, title, onComplete) {
      if (!title) {
        title = '';
      }
      SweetAlert.swal({
        title: title,
        text: message,
        type: 'success',
        confirmButtonColor: '#43913e'},
        function(choice) {
          if (onComplete) {
            onComplete(choice);
          }
        }
      );
    };

    this.confirm = function(message, title, onComplete) {
      if (!title) {
        title = '';
      }
      SweetAlert.swal({
        title: title,
        text: message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes'},
        function(choice) {
          if (onComplete) {
            onComplete(choice);
          }
        }
      );
    };

    this.confirm2 = function(message, title, confirmButtonText, cancelButtonText, onComplete) {
      if (!title) {
        title = '';
      }
      SweetAlert.swal({
        title: title,
        text: message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText},
        function(choice) {
          if (onComplete) {
            onComplete(choice);
          }
        }
      );
    };

    this.error = function(message, title, buttonText, onComplete) {
      if (!title) {
        title = '';
      }
      SweetAlert.swal({
        title: title,
        text: message,
        type: 'warning',
        confirmButtonColor: '#DD6B55',
        confirmButtonText: buttonText,
      },
        function(choice) {
          if (onComplete) {
            onComplete(choice);
          }
        }
      );
    };

    this.prompt = function(message, title, confirmButtonText, cancelButtonText, onComplete) {
      if (!title) {
        title = '';
      }
      SweetAlert.swal({
        title: title,
        text: message,
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#45173c',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText},
        function(choice) {
          if (onComplete) {
            onComplete(choice);
          }
        }
      );
    };
  }]);
