angular.module('hcap-analytics')
  .service('GroupService', ['$http', function ($http) {

    this.getAll=function(callback) {
      $http.get('./api/group/all').then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.get=function(guid, callback) {
      $http.get('./api/group/'+guid).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.create=function() {
      return $http.put('./api/group/').then(function(response) {
        return response.data.guid
      })
        .catch(function(err) {
          console.log(err)
          return null
        })
    }

    this.delete=function(guid) {
      return $http.delete('./api/group/'+guid).then(function(response) {
        return response.data.guid
      })
        .catch(function(err) {
          console.log(err)
          return null
        })
    }

    this.update=function(guid, name, list) {
      var obj = { name: name, list: list };
      return $http.post('./api/group/'+guid, obj).then(function(response) {
        return response.data
      })
        .catch(function(err) {
          console.log(err)
          return null
        })
    }
  }])
  .service('AssessmentService', ['$http', '$q', function ($http, $q){
    this.cached = {};
    this.promises = {};

    this.getAll=function(callback) {
      $http.get('./api/assessment/all').then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.get=function(guid,callback) {
      $http.get('./api/assessment/'+guid).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.create=function() {
      return $http.put('./api/assessment/').then(function(response) {
        console.log(response.data);
        return response.data.guid
      })
        .catch(function(err) {
          console.log(err)
          return null
        })
    }

    this.hide=function(guid,callback) {
      $http.post('./api/assessment/'+guid+"/hide").then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.unhide=function(guid,callback) {
      $http.post('./api/assessment/'+guid+"/unhide").then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.stop=function(guid,callback) {
      $http.post('./api/assessment/'+guid+"/stop").then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.start=function(guid,callback) {
      $http.post('./api/assessment/'+guid+"/start").then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.getAllUserAssessments=function(force) {
      if (!force && this.promises.userAssessments) {
        return this.promises.userAssessments;
      } else {
        this.promises.userAssessments = $http.get('./api/assessment/participant').then(response => {
          this.cached.userAssessments = response.data.assessments;
          return response.data.assessments;
        });

        return this.promises.userAssessments;
      }
    }

    this.getUserSelfAssessments = () => {
      return this.getAllUserAssessments().then(assessments => {
        return assessments.filter(assessment => !assessment.participant.designatedRater);
      });
    };

    this.getUserDesignatedAssessments = () => {
      return this.getAllUserAssessments().then(assessments => {
        return assessments.filter(assessment => {
          return assessment.participant.designatedRater && !assessment.participant.completed;
        });
      });
    };

    this.getForUser=function(userGuid,callback) {
      $http.get('./api/assessment/user/'+userGuid).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.getIds=function(callback) {
      return $http.get('./api/assessment/ids').then(function(response) {
        return callback(response.data);
      });
    }

    this.modifyProperty=function(guid,key,value,callback) {
      var obj={}
      obj[key]=value
      $http.post('./api/assessment/'+guid+"/property",obj).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.get=function(guid,callback) {
      $http.get('./api/assessment/'+guid).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.removeParticipant=function(guid,participantId,callback) {
      $http.delete('./api/assessment/'+guid+"/participant/"+participantId).then(function(response) {
        this.getAllUserAssessments(true);
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.reopenParticipant=function(guid,participantId,callback) {
      $http.post('./api/assessment/'+guid+"/participant/"+participantId+"/reopen").then(function(response) {
        this.getAllUserAssessments(true);
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.addParticipant=function(guid, newParticipant, notify, callback) {
      $http.post(`/api/assessment/${guid}/participant?notify=${notify}`, newParticipant).then(response =>  {
        this.getAllUserAssessments(true);
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.addParticipants=function(guid,participants,callback) {
      $http.post('./api/assessment/'+guid+"/participants",participants).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }
  }])
  .service('TemplateService', ['$http', '$q', function ($http, $q){
    this.promises = {};
    this.cached = {};

    this.getAll = function(force) {
      if (!force && this.promises.templates) {
        return this.promises.templates;
      } else {
        this.promises.templates = $http.get('./api/template/all').then(response => {
          this.cached.templates = response.data;
          return this.cached.templates;
        });

        return this.promises.templates;
      }
    }

    this.getCore=function(callback) {
      $http.get('./api/template/core').then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.get=function(guid,callback) {
      $http.get('./api/template/'+guid).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.save=function(guid,template,callback) {
      $http.post('./api/template/'+guid,template).then(function(response) {
        callback(true)
      }, function(err) {
        console.log(err)
        callback(false);
      })
    }

    this.create=function(callback) {
      $http.put('./api/template/').then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
        callback(false);
      })
    }

    this.updateStatus = function(guid,status,callback) {
      $http.post('./api/template/'+guid+'/status/'+status).then(function(response) {
        callback(true)
      }, function(err) {
        console.log(err)
        callback(false);
      })
    }

  }])
  .service('ParticipantService',['$http', '$location', function ($http, $location){
    this.sendNotification=function(participantId,callback){
      $http.post('./api/participant/'+participantId+'/notification').then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.sendDesignatedRaterNotification=function(participantId,email,callback){
      email = email.toLowerCase()
      $http.post('./api/participant/'+participantId+'/designatedRaterNotification/'+email).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.get=function(participantId, orgView, callback) {
      let endpoint = './api/participant/' + participantId;

      if (orgView) {
        endpoint += '?orgView=true';
      }

      $http.get(endpoint).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.saveResponse=function(participantId, key, value, callback) {
      var obj={
        key:key,
        value:value
      }
      $http.post('./api/participant/'+participantId+"/response",obj).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.finalizeResponses=function(participantId,callback) {
      $http.post('./api/participant/'+participantId+"/finalize").then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.getDesignatedRater=function(participantId,callback) {
      $http.get('./api/participant/designatedRater/'+participantId).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.saveDesignatedRaterResponse=function(participantId, key, value, callback) {
      var obj={
        key:key,
        value:value
      }
      $http.post('./api/participant/designatedRater/'+participantId+"/response",obj).then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }

    this.finalizeDesignatedRaterResponses=function(participantId,callback) {
      $http.post('./api/participant/designatedRater/'+participantId+"/finalize").then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }
  }])
  .service('StateService', ['$state', 'UserService', 'InvitesService', function($state, UserService, InvitesService) {
    this.land = function(event) {
      if (event) {
        event.preventDefault();
      }

      const user = UserService.getUser();
      InvitesService.update();

      if (user && user.role === 'Administrator') {
        window.location.pathname = '/v1';
      } else if ($state.params.redirect) {
        $state.go($state.params.redirect.state, $state.params.redirect.params);
      } else if (user && (!user.hasOwnProperty('consent'))) {
        localStorage.setItem('sectionSelected', 'profile');
        $state.transitionTo('profile', { require: true }, {reload: true});
      } else if (user && ['User', 'Organizer'].indexOf(user.role) !== -1) {
        localStorage.setItem('sectionSelected', 'home');
        $state.transitionTo('home');
      } else {
        $state.transitionTo('signin');
      }
    };
  }])
  .service('AssessmentStatsService',['$http', function ($http){
    this.get=function(callback) {
      $http.get('./api/assessmentStats').then(function(response) {
        callback(response.data)
      }, function(err) {
        console.log(err)
      })
    }
  }])
